import { Avatar, Grid, Stack, Typography } from '@mui/material'
import { SearchResult } from '../../../store/searcher/reducer'
import { preparePsychoAnalysysResult  } from '../../../store/searcher/service'
import { ResultPshychoTitle } from '../../../style/result'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'

type Props = {
  apiData: SearchResult
}

const searchTypes = ['PSYCH', 'SUMMARY','psych','summary']

export function PsychoProfileCommon(props: Props) {
  const result: any = preparePsychoAnalysysResult(props.apiData)

  const source = result.sources && Array.isArray(result.sources) && result.sources.find((src: any) => src && src.name && searchTypes.includes(src.name))?.name
  const person = result.data && Array.isArray(result.data) && result.data.length > 0 && result.data[0]
  return (
      <>
      { source && source.toLowerCase() === 'psych' && person && 
        <Stack>
          <Divider>
            <Chip label="General" size="small" style={ { marginBottom: 10} } />
          </Divider>
          
          <Stack direction="row" alignItems="center" justifyContent="start" sx={{margin: 3, display: "flex", flexFlow: "row wrap"}} spacing={2}>
            {
              person.image && person.image != '' &&
                <Avatar src={person.image} sx={{ width: 90, height: 90 }} />
            }
            {
              person.personName && <>
                <Typography fontSize="1rem">Person name:</Typography>
                  <ResultPshychoTitle>{person.personName}</ResultPshychoTitle>
              </>
            }
            {
              person.levelOfDanger && <>
              <Typography fontSize="1rem">Level of Danger:</Typography>
                <ResultPshychoTitle>{person.levelOfDanger}</ResultPshychoTitle>
            </>
            }
          </Stack>
          {
            person.predictedCharacteristics && Array.isArray(person.predictedCharacteristics) && person.predictedCharacteristics.length > 0 && <>
              <Divider>
                <Chip label="Characteristics" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
              </Divider>
              <Stack direction="row" justifyContent="center" spacing={1} sx={{margin: 1}}>
                {person.predictedCharacteristics.map((el: any) => {
                      return <ResultPshychoTitle >{el}</ResultPshychoTitle>
                    })}
              </Stack>
            </>
          }
          {
            person.psychologicalPortrait && <>
              <Divider>
                <Chip label="Psychological Portrait" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
              </Divider>
              <Typography fontSize="1rem">{person.psychologicalPortrait}</Typography>
            </>
          }
      </Stack>
        
      }

      {source && source.toLowerCase() === 'summary' && person && 
        <Stack>
          <Divider>
            <Chip label="General" size="small" style={ { marginBottom: 10} } />
          </Divider>
          <Stack direction="row" alignItems="center" justifyContent="start" sx={{margin: 3, display: "flex", flexFlow: "row wrap"}} spacing={2}>
            {
              person.image && <Avatar src={person.image} sx={{ width: 90, height: 90 }} />
            }
            {
              person.age && <>
                <Typography sx={{marginLeft: 3}} fontSize="1rem">Age: </Typography>
                <ResultPshychoTitle sx={{marginLeft: 3}}>{person.age}</ResultPshychoTitle>
              </>
            }
            {
              person.gender && <>
                <Typography sx={{marginLeft: 3}} fontSize="1rem">Gender: </Typography>
                <ResultPshychoTitle sx={{marginLeft: 3}}>{person.gender}</ResultPshychoTitle>
              </>
            }
            {
              person.country && <>
                <Typography sx={{marginLeft: 3}} fontSize="1rem">Country: </Typography>
                <ResultPshychoTitle sx={{marginLeft: 3}}>{person.country}</ResultPshychoTitle>
              </>
            }
          </Stack>
          {person.occupation && <>
                <Divider>
                  <Chip label="Occupation" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
                </Divider>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={3}>
                  <ResultPshychoTitle>{person.occupation}</ResultPshychoTitle>
                </Stack>
              </>
            }
          {
            person.summary && <>
              <Divider>
                <Chip label="Summary" size="small" style={ { marginTop: 5, marginBottom: 10} }/>
              </Divider>
              <Typography variant='body1' fontSize="1rem">
                {person.summary}
              </Typography>
            </>
          }
          
        </Stack>
      }

      {source && source !== '' && !person && <Stack>
            <Typography variant="bold" align='center'>No results</Typography>
          </Stack>
        }
    </>
    
  )
}
