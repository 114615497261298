import { Profile } from './reducer'

export const SET_STATE = 'settings/SET_STATE'
export interface SetState {
  type: typeof SET_STATE
  key: string
  value: string
}

export const FLUSH_STATE = 'settings/FLUSH_STATE'
export interface FlushState {
  type: typeof FLUSH_STATE
}

export const GET_PROFILE = 'settings/GET_PROFILE'
export interface GetProfile {
  type: typeof GET_PROFILE
}

export const SET_PROFILE = 'settings/SET_PROFILE'
export interface SetProfile {
  type: typeof SET_PROFILE
  data: Profile
}

export const SET_ON_BOARDING_STEP = 'settings/SET_ON_BOARDING_STEP'
export interface SetOnBoardingStep {
  type: typeof SET_ON_BOARDING_STEP
  step: number
}

export const SET_ON_BOARDING_STATUS = 'settings/SET_ON_BOARDING_STATUS'
export interface SetOnBoardingStatus {
  type: typeof SET_ON_BOARDING_STATUS
  value: boolean
}

export const FINISH_ON_BOARDING = 'settings/FINISH_ON_BOARDING'
export interface FinishOnBoarding {
  type: typeof FINISH_ON_BOARDING
}

export const FINISH_RULES_ACKNOWLEDGEMENT = 'settings/FINISH_RULES_ACKNOWLEDGEMENT'
export interface finishRulesAcknowledgement {
  type: typeof FINISH_RULES_ACKNOWLEDGEMENT
}

export const SET_RULES_ACKNOWLEDGEMENT_STATUS = 'settings/SET_RULES_ACKNOWLEDGEMENT_STATUS'
export interface SetRulesAcknowledgementStatus {
  type: typeof SET_RULES_ACKNOWLEDGEMENT_STATUS
  value: boolean
}

export const UPLOAD_IMAGE = 'settings/UPLOAD_IMAGE'
export interface UploadImage {
  type: typeof UPLOAD_IMAGE
  base64: string
}

export const DELETE_IMAGE = 'settings/DELETE_IMAGE'
export interface DeleteImage {
  type: typeof DELETE_IMAGE
}

export const PUT_DATA = 'settings/PUT_DATA'
export interface PutData {
  type: typeof PUT_DATA
  key: string
  value: string
}

export const SEND_EMAIL_UPDATE_VERIFICATION =
  'settings/SEND_EMAIL_UPDATE_VERIFICATION'
export interface SendEmailUpdateVerification {
  type: typeof SEND_EMAIL_UPDATE_VERIFICATION
  newEmail: string
  password: string
}

export const UPDATE_PASSWORD = 'settings/UPDATE_PASSWORD'
export interface UpdatePassword {
  type: typeof UPDATE_PASSWORD
  oldPassword: string
  password: string
}

export const UPDATE_PHONE = 'settings/UPDATE_PHONE'
export interface UpdatePhone {
  type: typeof UPDATE_PHONE
  code: string
  phone: string
  callback: () => void
}

export const GET_QR = 'settings/GET_QR'
export interface GetQr {
  type: typeof GET_QR
}

export const SET_QR = 'settings/SET_QR'
export interface SetQr {
  type: typeof SET_QR
  qr: string | null
}

export const TOGGLE_2STEP = 'settings/TOGGLE_2STEP'
export interface Toggle2step {
  type: typeof TOGGLE_2STEP
  code: string
  is2FA: boolean
}

export const DELETE_USER = 'settings/DELETE_USER'
export interface DeleteUser {
  type: typeof DELETE_USER
}

export const DELETE_2FA_USER = 'settings/DELETE_2FA_USER'
export interface Delete2FAUser {
  type: typeof DELETE_2FA_USER
  code: string
}

export const UPDATE_BALANCE = 'settings/UPDATE_BALANCE'
export interface UpdateBalance {
  type: typeof UPDATE_BALANCE
  value: number
}

export const GET_EMAIL_ACCOUNTING = 'settings/GET_EMAIL_ACCOUNTING'
export interface GetEmailAccounting {
  type: typeof GET_EMAIL_ACCOUNTING
  from: object
  to: object
  email: string
}

export const SET_EMAIL_ACCOUNTING = 'settings/SET_EMAIL_ACCOUNTING'
export interface SetEmailAccounting {
  type: typeof SET_EMAIL_ACCOUNTING
  email: string
}

export const POST_EMAIL_ACCOUNTING = 'settings/POST_EMAIL_ACCOUNTING'
export interface PostEmailAccounting {
  type: typeof POST_EMAIL_ACCOUNTING
  email: string
}

export const GET_BILLING_ADDRESS = 'settings/GET_BILLING_ADDRESS'
export interface GetBillingAddress {
  type: typeof GET_BILLING_ADDRESS
}

export const SET_BILLING_ADDRESS = 'settings/SET_BILLING_ADDRESS'
export interface SetBillingAddress {
  type: typeof SET_BILLING_ADDRESS
  data: object
}

export const EDIT_BILLING_ADDRESS = 'settings/EDIT_BILLING_ADDRESS'
export interface EditBillingAddress {
  type: typeof EDIT_BILLING_ADDRESS
  data: object
}

export const SWITCH_AUTO_RENEWAL = 'users/auto-renewal'

export const SWITCH_WEBINT_RENEWAL = 'users/auto-renewal-irbispro'

export interface SwitchAutoRenewal {
  type: typeof SWITCH_AUTO_RENEWAL
  value: boolean
}

export interface SwitchWebIntAutoRenewal {
  type: typeof SWITCH_WEBINT_RENEWAL
  value: boolean
}

export type SettingsAction =
  | SetState
  | FlushState
  | GetProfile
  | SetProfile
  | SetOnBoardingStep
  | SetOnBoardingStatus
  | FinishOnBoarding
  | UploadImage
  | DeleteImage
  | PutData
  | SendEmailUpdateVerification
  | UpdatePassword
  | UpdatePhone
  | GetQr
  | SetQr
  | Toggle2step
  | DeleteUser
  | Delete2FAUser
  | UpdateBalance
  | GetEmailAccounting
  | SetEmailAccounting
  | PostEmailAccounting
  | GetBillingAddress
  | SetBillingAddress
  | EditBillingAddress
  | SwitchAutoRenewal
  | SwitchWebIntAutoRenewal
  | finishRulesAcknowledgement
  | SetRulesAcknowledgementStatus
