import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  DialogContent,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  Avatar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Link,
  Popover,
  PopoverOrigin,
} from '@mui/material'
import sprite_ from '../../../sass/sprite.module.sass'
import { useAccordionStyles } from '../../../style/lookup'
import { Source } from '../../../store/searcher/reducer'
import {
  getCorsImageUrl,
  RenderProfileData,
} from '../../../component/searcher/RenderData'
import { search } from '../../../function/data'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useSelector } from '../../../store'
import { getViewResult } from '../../../store/searcher/selector'
import { prepareNameResult } from '../../../store/searcher/service'
import { usePopover } from '../../../hook/usePopover'

const searchExcludes = [
  'profile_id',
  'keyphrase',
  'keyword',
  'friendOf',
  'friendName',
  'friendProfileId',
  'friendImageUrl',
  'customrequestid',
  'type',
  'album_name',
  'setting=RABBIT_MQ_STATUS_MONITORING_QUEUE_NAME',
]

function getFilterIcon(source: Source, sourceFilter: Source[]) {
  if (sourceFilter.includes(source)) return sprite_['social__large_' + source]
  else return sprite_['social__small_' + source]
}

function getAccordionStyle(idx: number, profileUrl: object) {
  return {
    cursor:
      profileUrl && Object.keys(profileUrl).length !== 0 ? 'pointer' : 'auto',
  }
}

type Props = {
  pdfData: any
}

export function NamePagePdf({ pdfData: apiData }: Props) {
  const { t } = useTranslation()
  const accordionClasses = useAccordionStyles()

  const data = prepareNameResult(apiData)
  console.log(apiData)

  const initState = React.useMemo(() => {
    return data.data?.map((_) => true) || []
  }, [data.data])

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [filter, setFilter] = React.useState<Source[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  let filteredData: any = React.useMemo(() => {
    let filtered =
      filter.length > 0
        ? data.data?.filter((el) => filter.includes(el.from))
        : data.data

    if (searchValue.length > 0) {
      filtered = filtered?.filter((row) =>
        search(searchValue, row, searchExcludes),
      )
    }

    return filtered
  }, [data.data, filter, searchValue])

  const sources = React.useMemo(() => {
    return [...new Set(data.data?.map((el) => el.from))] || []
  }, [data.data])

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  const handleSourceFilter = (source: Source) => {
    setFilter((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  console.log('filtered data', filteredData)
  filteredData = filteredData?.filter((el: any) => !!el?.profileData)

  const fileName = React.useMemo(() => {
    if (apiData) {
      const date = new Date(String(apiData?.createdTime))
      const days = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const type =
        apiData?.type.charAt(0).toUpperCase() + apiData?.type.slice(1)
      const criteria = apiData.criteria.replace(' ', '_')
      return `${type}_${criteria}_${days}_${month}_${year}.pdf`
    }
  }, [apiData])

  return (
    <div style={{ padding: '30px', backgroundColor: 'white' }}>
      <Stack>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            lineHeight={1}
            mb={3}
          >
            <Typography variant="semiBold" fontSize={24} mr={2}>
              {t('searcher.name.popup.title')}
            </Typography>

            <Stack direction="row" alignItems="flex-end">
              {data.sources.map((el, idx) => (
                <IconButton
                  key={idx}
                  onClick={() => handleSourceFilter(el.name)}
                >
                  <Box className={getFilterIcon(el.name, filter)} />
                </IconButton>
              ))}
            </Stack>

            <OutlinedInput
              value={searchValue}
              sx={{
                background: 'none',
              }}
              placeholder={t('searcher.name.popup.searchPlaceholder')}
              onChange={(e) => setSearchValue(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Box className={sprite_.common__search} />
                </InputAdornment>
              }
              style={{
                marginLeft: 'auto',
                width: 192,
                height: 38,
                visibility: 'hidden',
              }}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {filteredData.map((el: any, idx: number) => {
            return (
              <Accordion
                key={idx}
                classes={accordionClasses}
                square
                expanded={
                  el.profileData && Object.keys(el.profileData).length !== 0
                    ? accordionOpen[idx]
                    : false
                }
                onChange={handleChangeAccordion(idx)}
              >
                <AccordionSummary
                  style={getAccordionStyle(idx, el.profileData)}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                    width="100%"
                  >
                    <Avatar
                      src={getCorsImageUrl(el.profileImageUrl)}
                      sx={{ height: 75, width: 75 }}
                    />
                    <Stack>
                      <Typography variant="bold">{el.profileName}</Typography>
                      <Typography variant="semiBold" color="secondary">
                        {el.profileData && el.profileData['Profile Headline']}
                      </Typography>
                      <Typography variant="bold" color="secondary">
                        {el.profileCity}
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent="space-between"
                      alignItems="flex-end"
                      style={{ marginLeft: 'auto', height: '100%' }}
                    >
                      <Box className={sprite_['social__small_' + el.from]} />
                      {el.profileUrl && (
                        <Link
                          variant="underlined"
                          href={el.profileUrl}
                          target="_blank"
                        >
                          @{el.profile_id}
                        </Link>
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {el.profileData && (
                    <RenderProfileData data={el.profileData} fullData={el} />
                  )}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </DialogContent>
      </Stack>
    </div>
  )
}
