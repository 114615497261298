import React, { useState } from 'react'
import { Avatar, Box, IconButton, Typography } from '@mui/material'

type Props = {
  comment?: any
}

const CommentItem = (props: Props) => {
  const {comment} = props
  //console.log('CommentDATa', comment)

  //state

  //handlers

  return <>
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "row",
        borderRadius: "16px",
        alignContent: "center",
        alignItems: "flex-start",
        margin: "16px",
      }}
    >
      <Avatar
        sx={{ width: 50, height: 50 }}
        aria-label="profile"
        src={comment.reference_user_image}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
        }}
      >
        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
        >
          {comment.reference_content}
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
          {comment.reference_user_name}
        </Typography>
        <Typography
          variant="subtitle1"
          color="#AAADB2"
          sx={{ fontSize: 12 }}
        >
          {comment.timestamp}
        </Typography>
      </Box>
      <IconButton sx={{ marginLeft: "auto" }} aria-label="arrow">
        {/*<img src={arrowIcon} alt="arrow" />*/}
      </IconButton>
    </Box>
  </>
}

export default CommentItem