import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined as EditIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from '../../store'
import { Button, Link, Stack, Typography, Grid } from '@mui/material'
import { Dialog2FAInput } from './Dialog2FAInput'
import { Dialog2FAQr } from './Dialog2FAQr'
import { DialogBillingAddress } from './DialogBillingAddress'
import { DialogEmailAccounting } from './DialogEmailAccounting'
import { PopupAsk } from '../../component/PopupAsk'
import { switchAutoRenewal, switchWebIntAutoRenewal } from '../../store/settings/actionCreator'

export function FormOther() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { twoFactorQr, is2FAEnabled, isAutoRenewal, webIntisAutoRenewal } = useSelector(
    (state) => state.settings,
  )
  const [isAutoRenewalOpen, setIsAutoRenewalOpen] = React.useState(false)
  const [isWebIntAutoRenewalOpen, setWebIntisAutoRenewalOpen] = React.useState(false)

  const [twoFAQrOpen, setTwoFAQrOpen] = React.useState(false)
  const [twoFAInputOpen, setTwoFAInputOpen] = React.useState(false)
  const [billingAddressOpen, setBillingAddressOpen] = React.useState(false)
  const [emailAccountingOpen, setEmailAccountingOpen] = React.useState(false)

  React.useEffect(() => {
    if (twoFactorQr) setTwoFAQrOpen(true)
  }, [twoFactorQr])

  function handle2FAButton(switchStatus: boolean) {
    const nowEnabled = is2FAEnabled === true
    const nowDisabled = is2FAEnabled === false
    const turnOn = switchStatus
    const turnOff = !switchStatus

    if (nowDisabled && turnOn) setTwoFAQrOpen(true)
    else if (nowEnabled && turnOff) setTwoFAInputOpen(true)
  }

  function handleAutoRenewalButton(switchStatus: boolean) {
    const nowEnabled = isAutoRenewal === true
    const nowDisabled = isAutoRenewal === false

    const turnOn = switchStatus
    const turnOff = !switchStatus

    if (nowDisabled && turnOn) setIsAutoRenewalOpen(true)
    else if (nowEnabled && turnOff) setIsAutoRenewalOpen(true)
  }

  function handlewebIntAutoRenewalButton(switchWebIntStatus: boolean) {
    const nowWebIntEnabled = webIntisAutoRenewal === true
    const nowWebIntDisabled = webIntisAutoRenewal === false

    const turnWebIntOn = switchWebIntStatus
    const turnWebIntOff = !switchWebIntStatus

    if (nowWebIntDisabled && turnWebIntOn) setWebIntisAutoRenewalOpen(true)
    else if (nowWebIntEnabled && turnWebIntOff) setWebIntisAutoRenewalOpen(true)
  }


  function handleYesAutoRenewal() {
    dispatch(switchAutoRenewal(!isAutoRenewal))
  }

  function handleWebIntYesAutoRenewal() {
    dispatch(switchWebIntAutoRenewal(!webIntisAutoRenewal))
  }

  // const handleChangeLanguage = async (lang: string) => {
  //   await i18n.changeLanguage(lang)
  //   dispatch(setLanguage(lang))
  // }

  return (
    <>
      <Typography variant="semiBold" fontSize={16}>
        {t('settings.other')}
      </Typography>

  <Grid container direction="row">
     <Grid item xs={4} style={{ padding: 10 }}> 
          <Typography variant="semiBold">
            {t('field.label.2stepVerification')}
          </Typography>
     </Grid>
     <Grid item xs={4}>
     <Stack direction="row" style={{ gap: 6 }}>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={is2FAEnabled === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handle2FAButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={is2FAEnabled === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handle2FAButton(false)}
            >
              {t('common.off')}
            </Button>
            </Stack>
     </Grid>
     <Grid item xs={4}>
     <Link variant="semiBold" color="primary" target="_blank" 
      href="https://irbis-user-manual.espysys.com/irbis-user-manual/accessing-the-system/irbis-2fa-activation">
              {t('settings.2stepVerificationBtn')}
            </Link>
     </Grid>
     <Grid item xs={4} style={{ padding: 10 }}> 
          <Typography variant="semiBold">
            {t('settings.autoRenewal')}
          </Typography>
     </Grid>
     <Grid item xs={4}>
     <Stack direction="row" style={{ gap: 6 }}>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={isAutoRenewal === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handleAutoRenewalButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={isAutoRenewal === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handleAutoRenewalButton(false)}
            >
              {t('common.off')}
            </Button>
      </Stack>
     </Grid>
     <Grid item xs={4}>
     </Grid>
     <Grid item xs={4} style={{ padding: 10 }}> 
          <Typography variant="semiBold">
            {t('settings.irbisProAutoRenewal')}
          </Typography>

     </Grid>
     <Grid item xs={4}>
        <Stack direction="row" style={{ gap: 6 }}>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={webIntisAutoRenewal === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handlewebIntAutoRenewalButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={webIntisAutoRenewal === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handlewebIntAutoRenewalButton(false)}
            >
              {t('common.off')}
            </Button>
        </Stack>
     </Grid>
     <Grid item xs={4}>
     </Grid>
  </Grid>

      <Stack width={340} spacing={1} mt={4}>
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="semiBold">
            {t('field.label.2stepVerification')}
          </Typography>
          <Stack direction="row" style={{ gap: 6 }}>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={is2FAEnabled === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handle2FAButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={is2FAEnabled === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handle2FAButton(false)}
            >
              {t('common.off')}
            </Button>
            <Link 
              variant="semiBold" 
              color="primary" 
              style={{ display: 'flex', justifyContent: 'space-between'}}
              target="_blank" href="https://irbis-user-manual.espysys.com/irbis-user-manual/accessing-the-system/irbis-2fa-activation">
          
              {t('settings.2stepVerificationBtn')}
          
            </Link>
          </Stack>
          
        </Stack> */}
        {/*General Package Autorenewal*/}
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="semiBold">
            {t('settings.autoRenewal')}
          </Typography>
          <Stack direction="row">
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={isAutoRenewal === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handleAutoRenewalButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={isAutoRenewal === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handleAutoRenewalButton(false)}
            >
              {t('common.off')}
            </Button>
          </Stack>
        </Stack> */}

        {/*IrbisPro Package Autorenewal*/}
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="semiBold">
            {t('settings.irbisProAutoRenewal')}
          </Typography>
          <Stack direction="row">
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={webIntisAutoRenewal === true ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handlewebIntAutoRenewalButton(true)}
            >
              {t('common.on')}
            </Button>
            <Button
              style={{ padding: '6px 0', minWidth: 'unset', width: 44 }}
              variant={webIntisAutoRenewal === false ? 'contained' : 'text'}
              size="large"
              fullWidth
              onClick={() => handlewebIntAutoRenewalButton(false)}
            >
              {t('common.off')}
            </Button>
          </Stack>
        </Stack> */}

        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          fullWidth
          onClick={() => setBillingAddressOpen(true)}
        >
          {t('settings.billingAddress')}
        </Button>
        {/* Hide the 'email accounting' button in the settings page */}
        <Button
          style={{ display: "none" }}
          startIcon={<EditIcon />}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setEmailAccountingOpen(true)}
        >
          {t('settings.emailAccounting')}
        </Button>
      </Stack>

      {twoFAQrOpen && (
        <Dialog2FAQr
          qrCode={twoFactorQr}
          onClose={() => setTwoFAQrOpen(false)}
          handle2FAInputOpen={() => setTwoFAInputOpen(true)}
        />
      )}

      {isAutoRenewalOpen && (
        <PopupAsk
          onYes={handleYesAutoRenewal}
          onClose={() => setIsAutoRenewalOpen(false)}
        />
      )}

      {isWebIntAutoRenewalOpen && (
        <PopupAsk
          onYes={handleWebIntYesAutoRenewal}
          onClose={() => setWebIntisAutoRenewalOpen(false)}
        />
      )}

      {twoFAInputOpen && (
        <Dialog2FAInput
          mode="toggle2FA"
          onClose={() => setTwoFAInputOpen(false)}
        />
      )}

      {billingAddressOpen && (
        <DialogBillingAddress onClose={() => setBillingAddressOpen(false)} />
      )}

      {emailAccountingOpen && (
        <DialogEmailAccounting onClose={() => setEmailAccountingOpen(false)} />
      )}
    </>
  )
}
