import * as React from 'react'
import { useTranslation } from 'react-i18next'
import _package from "../sass/package.module.sass"
import { Box, Typography, Stack, StyledEngineProvider, useTheme } from '@mui/material'
import { useDispatch, useSelector } from '../store'
import { PrivatePage } from '../component/PagePrivate'
import { fetchPackage } from '../store/package/actionCreator'
import { useAuthEffect } from '../hook/useAuthEffect'
import { IPackage, IPackageCustomized } from '../store/package/reducer'
import { useParams } from 'react-router-dom'
import { api } from '../function/axios'
import { PackageItem } from '../component/PackageItem'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'

type PackageModeType = {
  mode?: 'general' | 'webinthub'
}

export function PackagePage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = React.useState(false)
  // GET request in DB coloumn admin_maintenance
  const fetchMaintenanceStatus = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/admin_settings/maintenance',
      })
      setIsMaintenanceEnabled(response.data)
    } catch (error) {
      console.error('Failed to fetch maintenance status:', error)
    }
  }
  
  React.useEffect(() => {
    fetchMaintenanceStatus()
  }, [])
  useAuthEffect(() => {
    dispatch(fetchPackage)
  })

  const { packages } = useSelector((state) => state.package)

  const user = useSelector((state) => state.settings)

  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/packages")
    }
  }, [])

  const [purchaseOpen, setPurchaseOpen] = React.useState<IPackage | null>(null)
  const [customOpen, setCustomOpen] = React.useState(false)
  
  //const location = useLocation()
  const packageMode: PackageModeType = useParams()
  //console.log('package', packageMode)


  let showAll = true
  let showWebint = ""
  if (packageMode && packageMode?.mode) {
    showWebint = packageMode.mode
    showAll = false
  }

  /*const show : any = location.state
  console.log('show', show)
  if (show) {
    if (show['show']) {
      showWebint = show['show']
    } 
  }
  if (location.state) {
    showAll = false
  }*/

  const showWebintAdvanced = () => {
    //console.log(" user.irbisproUpgrade = ", user)
    if ( (user.irbisproUpgrade !== true && (showWebintOrSubpackage(user.additionalPackageEndDate) === 'webinthub_subpackage'))) {
      return 'webinthub_upgrade'
    }
  }

  const showWebintOrSubpackage = (dateTime : Date | null) : string => {
    let someDate : Date = new Date()
    const date = new Date()
    if (dateTime) {
      someDate = new Date(dateTime)
    } else {
      return 'webinthub'
    }
    
    if (someDate) {
      if (date.valueOf() - someDate.valueOf() > 0 ) {
        return 'webinthub'
      } else {
        if (user.additionalPackageType === 'webinthub_demo') {
          return 'webinthub'
        } else {
          return 'webinthub_subpackage'
        }
      }
    } else {
      return 'webinthub'
    }
  }

  const isWebintActive = (dateTime: Date | null) : boolean => {
    let someDate : Date = new Date()
    const date = new Date()
    if (dateTime) {
      someDate = new Date(dateTime)
    } else {
      return false
    }
    if (someDate) {
      if (someDate.valueOf() - date.valueOf() > 0 ) {
        return true
      }
    }
    return false
  }

  const urlParams = new URLSearchParams(window.location.search)
  const getShow = urlParams.get('show')
  if (getShow && ['webinthub', 'general'].includes(getShow)) {
    showAll = false
    showWebint = getShow
  }

  const [isAnnual, setIsAnnual] = React.useState(false)

  return (
    <PrivatePage style={{padding: 0}}>

      <StyledEngineProvider injectFirst>
        {
          (showAll || showWebint === 'general') && <Box className={_package.main_section}>
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
              <Typography className={_package.title}>Main Balance Packages</Typography>
              {/*<Typography className={_package.subtitle}>Save 25% on annual</Typography>*/}
              <Stack className={_package.annual_container} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Box className={!isAnnual ? _package.annual_option__active : _package.annual_option} onClick={() => setIsAnnual(false)}>Monthly</Box>
                <Box className={isAnnual ? _package.annual_option__active : _package.annual_option} onClick={() => setIsAnnual(true)}>Annual</Box>
              </Stack>
            </Stack>

            <Box className={_package.main_container}>

              {
                packages &&
                (showAll || showWebint === 'general') &&
                packages
                  .filter( el => (!el.isFree))
                  .filter( el => (el.isActive && (el.type === 'general' || el.type === 'personal')))
                  .map((el, idx) => <PackageItem
                      data={el}
                      key={idx}
                      packagesQuantity={packages.length}
                      isAnnual={isAnnual}
                    />,
                  )
              }

            </Box>
          </Box>
        }
        {
          (showAll || showWebint === 'webinthub') && <Box
            sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none!important',
              },
            }}
            className={_package.pro_section}
          >
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
              <Typography className={_package.title}>Irbis Pro Packages</Typography>
              {
                packages && <Stack className={_package.annual_pro_container} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <Box className={!isAnnual ? _package.annual_pro_option__active : _package.annual_pro_option} onClick={() => setIsAnnual(false)}>Monthly</Box>
                  <Box className={isAnnual ? _package.annual_pro_option__active : _package.annual_pro_option} onClick={() => setIsAnnual(true)}>Annual</Box>
                </Stack>
              }
            </Stack>

            <Box className={_package.main_container}>
            {
                packages &&
                (showAll || showWebint === 'webinthub') &&
                packages
                  .filter( el => (el.isActive && (
                    (el.id === user.additionalPackageId && isWebintActive(user.additionalPackageEndDate))
                    ))) 
                  .map((el, idx) =>
                    <PackageItem
                      data={el}
                      key={idx}
                      packagesQuantity={packages.length}
                      isAnnual={isAnnual}
                      webintActive={isWebintActive(user.additionalPackageEndDate)}
                      isBought={el.id === user.additionalPackageId && isWebintActive(user.additionalPackageEndDate)}
                    />,
                  )
              }
              {
                packages &&
                (showAll || showWebint === 'webinthub') &&
                packages
                  .filter( el => (el.isActive && ( 
                    (el.type !== 'telegrambt' && el.type === showWebintOrSubpackage(user.additionalPackageEndDate) || el.type === showWebintAdvanced())
                    ))) 
                  .map((el, idx) =>
                    <PackageItem
                      data={el}
                      key={idx}
                      packagesQuantity={packages.length}
                      isAnnual={isAnnual && !isWebintActive(user.additionalPackageEndDate)}
                      webintActive={isWebintActive(user.additionalPackageEndDate)}
                    />,
                  )
              }

            </Box>
          </Box>
        }
        {
          (showAll || showWebint === 'webinthub') && <Box className={_package.pro_section}>
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
              <Typography className={_package.title}>Custom Packages</Typography>
            </Stack>

            <Box className={_package.main_container}>
              {
                packages &&
                (showAll || showWebint === 'webinthub') &&
                packages
                  .filter( el => (el.isActive && (el.type === 'custom')))
                  .map((el, idx) =>
                    <PackageItem
                      data={el}
                      key={idx}
                      packagesQuantity={packages.length}
                      isAnnual={isAnnual && !isWebintActive(user.additionalPackageEndDate)}
                    />,
                  )
              }

            </Box>
          </Box>
        }

      </StyledEngineProvider>
    </PrivatePage>
  )
}
