import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ResultCriteria,
  ResultCriteriaTitle,
} from '../style/result'
import { useDispatch } from '../store'
import { PrivatePage } from '../component/PagePrivate'
import { useParams } from 'react-router-dom'
import { SearchResult } from '../store/searcher/reducer'
import { fetchResultByIdAsync } from '../store/searcher/actionCreator'
import { NameCommon } from '../container/searcher/details/NameCommon'
import { DeepWebCommon } from '../container/searcher/details/DeepWebCommon'
import { PhoneCommon } from '../container/searcher/details/PhoneCommon'
import { ResultName, ResultNameTitle, ResultTitle, ResultVerified } from '../style/result'
import { PostCommon } from '../container/searcher/details/PostCommon'
import { Image, prepareCommonResult, PreparedCommonResult } from '../store/searcher/service'
import sprite_ from '../sass/sprite.module.sass'
import { getTitle } from '../function/string'
import { RenderImage } from '../component/searcher/RenderData'
import { Masonry } from '@mui/lab'


export function ResultPage() {
  //states
  const { t } = useTranslation()
  const { id } = useParams() as {
    id: string;
  }
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [result, setResult] = React.useState<SearchResult>({} as SearchResult)
  const [viewData, setViewData] = React.useState<PreparedCommonResult>()
  const [activeImage, setActiveImage] = React.useState<Image>()
  //callbacks
  const dispatchSingleResult = React.useCallback((id: string) => {
    setLoading(true)
    dispatch(fetchResultByIdAsync(id, (data: SearchResult) => {
      
      setResult(data)
      const vd = prepareCommonResult(data)
      setViewData(vd)
          
      if (vd?.images?.length) {
        setActiveImage(vd.images[0])
      }

      setLoading(false)
    }))
  }, [dispatch])

  //effects
  React.useEffect(() => {
    dispatchSingleResult(String(id))
  }, [id, dispatchSingleResult])
  //handlers

  const handleImageClick = (el: any, image: Image) => {
    el.preventDefault()
    setActiveImage(image)
  }
  function getResultTopIcon(){
    // return apiData?.type !== 'deepweb' ? apiData?.type : viewData?.deepwebType
    let iconType
    if (result && result.type){
      if (result.type === 'deepweb' && viewData && viewData.deepwebType){
        iconType = viewData.deepwebType
      }
      else if (result.type === 'combined_id' && result.sources.find((src) => src.name = 'instanavigation_person')) {
        iconType = 'instanavigation_person'
      }
    }
    return iconType ? sprite_[`social__small_${iconType}`] : undefined
  }
  console.log('fetchResultById', result)
  return (
    <PrivatePage>
      {
        loading && <CircularProgress />
      }
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
      <Typography fontSize={16} color={'#747474'} sx={{}}>
            {result?.type && ['phone', 'combined_phone'].includes(result.type) && 'Phone lookup results'}
            {result?.type && ['name', 'combined_name', 'combined_id'].includes(result.type) && 'Social networks profiles'}
            {result?.type && ['combined_email'].includes(result.type) && 'Search by email'}
            {result?.type === 'post' && 'Posts observer results'}
            {result?.type === 'deepweb' && 'Pluto lookup results'}
            {result?.type === 'face_search' && 'Face Search'}
            {result?.type === 'sentiment_analysys' && 'Sentiment Analysys'}
      </Typography>
      {
            result?.type !== 'sentiment_analysys' && <ResultCriteria>
              <Box
                className={getResultTopIcon()}
                sx={{marginRight: '10px'}}
              />
              {
                ['face_search', 'irbis_eye', 'photo_searcher'].includes(result?.type + '')
                  ? <img
                    src={result?.criteria}
                    alt=''
                    width='40'
                    height='50'
                    style={{ objectFit: 'cover' }}
                  />
                  : <Tooltip title={result?.criteria || ''} placement="bottom">
                    <ResultCriteriaTitle>{result?.criteria}</ResultCriteriaTitle>
                  </Tooltip>
              }
            </ResultCriteria>
          }
      </Stack>
      {viewData && <>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
            {
              viewData.foundSources?.length > 0 && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                {
                  viewData.foundSources.map((app, idx) =>
                    <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                      <Box key={idx} className={sprite_[`social__large_${app}`]} sx={{marginRight: '8px'}} />
                    </Tooltip>,
                  )
                }
              </Stack>
            }
            {
              viewData.notFoundSources?.length > 0 && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{marginLeft: '20px'}}>
                <Typography sx={{marginRight: '5px'}} fontSize={14} color={'#747474'}>Not found:</Typography>
                {
                  viewData.notFoundSources.map((app, idx) =>
                    <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                      <Box key={idx} className={sprite_[`social__small_${app}`]} sx={{marginRight: '8px', opacity: '0.6'}} />
                    </Tooltip>,
                  )
                }
              </Stack>
            }
          </Stack>

          <Stack sx={{padding: '20px'}}>
            <Grid container spacing={2}>
              {
                activeImage && <Grid item xs={'auto'}>
                  <Box sx={{width: '220px', height: '250px'}}>
                    <RenderImage
                      _key={'activeImage'}
                      val={activeImage.image}
                      imgHeight={'250px'}
                    />
                  </Box>
                </Grid>
              }
              <Grid item xs>
                <Stack direction={'column'} alignItems={'space-between'} justifyContent={'space-between'} sx={{height: '100%', width: '100%'}}>
                  {
                    viewData.images.length > 0 && <Stack direction={'row'} flexWrap={'wrap'} rowGap={1}>
                      {
                        viewData.images.map((image, idx) => <Box key={idx} sx={{width: '67px', height: '75px', marginRight: '10px', position: 'relative'}}>
                          <RenderImage
                            _key={idx + ''}
                            val={image.image}
                            imgHeight={'75px'}
                            onClick={(e) => handleImageClick(e, image)}
                          />
                          <Box className={sprite_[`social__small_${image.app}`]} sx={{marginRight: '8px', width: '20px', position: 'absolute', top: '2px', left: '2px'}} />
                        </Box>)
                      }
                    </Stack>
                  }
                  <Stack direction={'column'} sx={{margin: '20px 0'}}>
                    <Stack direction={'row'}>
                      <ResultName>
                        <Tooltip title={viewData.name || ''} placement="bottom">
                          
                          <ResultNameTitle>{ result?.type === 'face_search' ? <Avatar alt="Remy Sharp" src={viewData.name} /> : 
                          viewData.name}</ResultNameTitle>
                        
                        </Tooltip>
                      </ResultName>
                      {result?.status === 'finished' && <ResultVerified>Verified 100%</ResultVerified>}
                    </Stack>
                    {
                      viewData.createdDate && <Stack direction={'row'} sx={{marginTop: '10px'}}>
                        <Typography fontSize={16} color={'#214A98'} fontWeight={700} sx={{marginRight: '10px'}}>Retrieved:</Typography>
                        <Typography fontSize={16} color={'#747474'} fontWeight={400}>{viewData.createdDate}</Typography>
                      </Stack>
                    }
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          <Box sx={{ width: '100%', marginBottom: '30px' }}>
            <Masonry columns={2} spacing={1}>
              {
                viewData.names.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Names:</ResultTitle>
                    {
                      viewData.names.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        {
                          name?.app && <Tooltip title={name?.app} placement="top">
                            <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          </Tooltip>
                        }
                        <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.name}</Typography>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              {
                viewData.socialNetworks.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Social Networks:</ResultTitle>
                    {
                      viewData.socialNetworks.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <Box className={sprite_[`social__small_${name?.app}`]} sx={{marginRight: '8px'}} />
                        {
                          name.url
                            ? <a href={name.url} target={'_blank'}>
                              <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.socialNetwork}</Typography>
                            </a>
                            : <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.socialNetwork}</Typography>
                        }

                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              
              {
                viewData.birthday.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Birthday:</ResultTitle>
                    {
                      viewData.birthday.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                        <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.birthday}</Typography>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              {
                viewData.emails.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Emails:</ResultTitle>
                    {
                      viewData.emails.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                        <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.email}</Typography>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              {
                viewData.urls.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Web Sites:</ResultTitle>
                    {
                      viewData.urls.map((url, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <a href={url.link} target={'_blank'}>
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{url.title}</Typography>
                        </a>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              {
                viewData.addresses.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Addresses:</ResultTitle>
                    {
                      viewData.addresses.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                        <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.address}</Typography>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }
              
            </Masonry>
          </Box>

          {
            result && ['phone', 'combined_phone'].includes(result.type) && <>
              <ResultTitle>Details:</ResultTitle>
              <PhoneCommon apiData={result} />
            </>
          }

          {
            result
              && ['name', 'combined_name', 'combined_id'].includes(result.type)
              && <NameCommon apiData={result} />
          }

          {
            result && result.type === 'post' && <>
              <ResultTitle>Posts:</ResultTitle>
              <PostCommon apiData={result} />
            </>
          }

          {
            result
              && ['deepweb', 'combined_email', 'combined_name', 'combined_phone', 'combined_id'].includes(result.type)
              && <DeepWebCommon apiData={result} />
          }
          {
            result
              && ['advanced_collection_search'].includes(result.type) 
              && <>
              <div><pre>{JSON.stringify(result, null, 1)}</pre></div>
              </>
          } 

        </>
      }

    </PrivatePage>
  )
}
