import { PackageAction } from './actionType'
import { ILookup, Source } from '../searcher/reducer'

export interface IFeature {
  title: string
  subTitle: string
  info: string
}

interface PackageBase {
  id: number
  type: 'general' | 'custom' | 'personal' | 'webinthub' | 'telegrambt' | 'webinthub_subpackage' | 'webinthub_upgrade' | 'demo' | 'webinthub_demo'
  title: string
  subTitle: {
    type: string
    context: string
  }
  activeDay: number
  feature1: IFeature
  feature2: IFeature
  feature3: IFeature
  button: string
  isActive: boolean
  isFree: boolean
  packageId: number
}

interface PackageQuotas {
  quotaProfilerCases: string
  quotaProfilerCrawlers: string
  quotaProfilerLookup: string
  quotaProfilerMonitoring: string
  quotaProfilerPeriodic: string
  quotaTrialProfilerCases: string
  quotaTrialProfilerCrawlers: string
  quotaTrialProfilerLookup: string
  quotaTrialWebIntCases: string
  quotaTrialWebIntCrawlers: string
  quotaTrialWebIntMonitoring: string
  quotaTrialWebIntPeriodic: string
  quotaWebIntCases: string
  quotaWebIntCrawlers: string
  quotaWebIntLookup: string
  quotaWebIntMonitoring: string
  quotaWebIntPeriodic: string

  additionalProfilerCases: string
  additionalProfilerCrawlers: string
  additionalProfilerLookup: string
  additionalProfilerMonitoring: string
  additionalProfilerPeriodic: string
  additionalWebIntCases: string
  additionalWebIntCrawlers: string
  additionalWebIntLookup: string
  additionalWebIntMonitoring: string
  additionalWebIntPeriodic: string
}

export interface IPackage extends PackageBase {
  productId: number
  annualProductId: number
  uid: string
  price: number
  annualPrice: number
  packageBalance: number
  creditsPerUnit : number
  bonus: number
  sources: Source[]
  lookups: ILookup[]
  // annualBonus: number,
  packageQuotas: PackageQuotas,
}

export interface IPackageCustomized extends PackageBase {
  price: number
  annualPrice: number
  packageBalance: null
  bonus: null
  feature4: IFeature
  sources: null
  productId: number
  annualProductId: number
  // annualBonus: number
  packageQuotas: PackageQuotas
}

export type PackageState = {
  packages: Array<IPackage | IPackageCustomized> | null
}

const initialState: PackageState = {
  packages: null,
}

export function packageReducer(state = initialState, action: PackageAction) {
  switch (action.type) {
    case 'package/SET_PACKAGE':
      return {
        ...state,
        packages: action.data,
      }
    default:
      return state
  }
}
