import { getCorsImageUrl } from '../component/searcher/RenderData'

export type ImageTypes = 'png' | 'jpg' | 'gif'

const width = 200
const height = 200

export function dataURItoBlob(dataURI: string) {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
  return new Blob([ia], { type: mime })
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export function resizeImage(file: File) {
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')

  const resize = () => {
    canvas.width = width
    canvas.height = height

    const context = canvas.getContext('2d')

    if (context) context.drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL('image/jpeg')
    return dataURItoBlob(dataUrl)
  }

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'))
      return
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize())
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export function getByBase64(base64: string) {
  return `data:image/png;base64,${base64}`
}

export function getUrlFromBlob(data: Blob, extension: string) {
  const file = new Blob([data], { type: extension })
  return URL.createObjectURL(file)
}

export async function checkIfImageExists(val: any) {
  const http = new XMLHttpRequest()

  const url = getCorsImageUrl(val)
  if (url.length > 300) return true
  http.open('HEAD', url, true)
  http.send()

  http.onload = function () {
    console.log(http)
    if (http.status === 200 && http.responseText !== 'URL signature expired') {
      //if(statusText == OK)
      console.log('image exists')
      return true
    } else {
      console.log("image doesn't exist")
      return false
    }
  }
}
