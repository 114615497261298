import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  Grid,
  IconButton,
  Typography,
  Stack,
  Box,
  useTheme,
  Dialog,
  DialogActions,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  Card, List, ListItem, ListItemText, Menu, MenuItem,
} from '@mui/material'
import { useDispatch, useSelector } from '../store'
import { getLookups } from '../store/searcher/selector'
import {
  deleteResults,
  fetchLookup,
  startLookup,
  toggleViewResult,
} from '../store/searcher/actionCreator'
import { fetchNotification, fetchTable, hideSnackbar, showSnackbar } from '../store/common/actionCreator'
import { PrivatePage } from '../component/PagePrivate'
import { PopupAsk } from '../component/PopupAsk'
import sprite_ from '../sass/sprite.module.sass'
import { ILookup, SearchResult, Source, Type } from '../store/searcher/reducer'
import { HeaderData, TableRef } from '../component/datatable/types'
import { DataTable } from '../component/datatable'
import { phone, stringSize, validateForm } from '../function/validation'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { useAuthEffect } from '../hook/useAuthEffect'
import { io, Socket } from 'socket.io-client'
import { useState, useEffect, useRef } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { fetchProfile } from '../store/settings/actionCreator'
import { getRulesAcknowledgement } from '../store/settings/selector'
import { Acknowledgement } from '../container/onboarding/rulesIRBIS'
import { MaintenancePopup } from '../container/settings/MaintenancePopup'
import { api } from '../function/axios'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useCardStyles } from '../style/card'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LookupInline } from '../component/searcher/LookupInline'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'
import { RenderTableBody } from '../component/searcher/RenderTableBody'
import { WelcomePackageRule } from '../container/onboarding/welcome-package-rule'
import { getFirebaseUser, getToken } from '../store/auth/service'

const tableLimit = 20
const socketUrl : string | undefined = process.env.REACT_APP_WEBSOCKET_HOST
const socket = socketUrl && io(socketUrl)

const schema = Joi.object({
  type: Joi.any(),
  inputType: Joi.when('type', {
    is: 'deepweb' || 'ipgeo_number',
    then: Joi.string(),//.max(100).required(),
    otherwise: Joi.any(),
  }),
  
  input: Joi.when('type', {
    switch: [
      { is: 'phone', then: phone.label(t('field.label.phone')) },
      { is: 'combined_phone', then: phone.label(t('field.label.phone')) },
      { is: 'face_search', then: Joi.string() },
      { is: 'combined_email', then: Joi.string().email({ tlds: { allow: false } }).label(t('field.label.email')) },
      { is: 'deepweb', then: Joi.when('inputType', {
          switch: [
            { is: 'email', then: Joi.string().email({ tlds: { allow: false } }).label(t('field.label.email')) },
            { is: 'phone', then: Joi.string().required().label(t('field.label.phone')).custom((value, helper) => { 
              if (value[0] !== '+') {
                return helper.message({
                  custom: "You must add + before the phone number",
                  })
              }
              if (!isValidPhoneNumber(value)) {
                  return helper.message({
                      custom: t('message.error.phone'),
                      })
              } else {
                  return true
              }
              }),
          },
          ],
          otherwise: stringSize,
        }),
      },
      { is: 'irbis_eye', then: Joi.string() },
      { is: 'sentiment_analysys', then: Joi.string().min(1).max(3500)},
      { is: 'post', then: Joi.string().min(3).max(100).required()},
      { is: 'name', then: Joi.string().min(2).max(100).required()},
      { is: 'combined_name', then: Joi.string().min(2).max(100).required()},
      { is: 'kyc', then: Joi.string()},
      { is: 'web_collection', then: Joi.string()},
      { is: 'ip_geo', then: Joi.when('inputType', {
        switch: [
         { is: 'geolocation', then: Joi.string().ip().label("Wrong IP:")} ,
        ],
      })},
      { is: 'combined_id', then: Joi.string()},
      { is: 'country_fullname', then: Joi.string()},
      { is: 'name_by_country', then: Joi.string()
          //.regex(/(\w+( \w+)+)/).message("Full name requires more than 1 word")},
          //.regex(/^((?:\p{L}){2,30}\s?){2,4}$/)
          .regex(/^(([\D\S]+)( ([\D\S]+))+)$/)
          .message("Full name requires more than 1 word")},
      { is: 'photo_searcher', then: Joi.string()},
      { is: 'phone_list', then: Joi.string()},
      { is: 'ipgeo_number', then: Joi.when('inputType', {
          switch: [
            { is: 'geolocation_number', then: Joi.string() },
            { is: 'email_domain_validator', then: Joi.string() },
            
          ],
          otherwise: stringSize,
        }),
      },
      { is: 'psycho_profile', then: Joi.string().min(2).required().custom((value, helpers) => {
        if (String(value).includes(" ")){
          return helpers.message({
            custom: "no spacaes allowed in facebook ID.",
          })
        }
        return value
      },"no spaces validator")},
    ],
    otherwise: stringSize,
  }),
})

const tableHeaderData: HeaderData[] = [
  { key: 'criteria', text: t('searcher.table.criteria') },
  {
    key: 'type',
    text: t('searcher.table.type'),
    className: 'wideVisible',
  },
  {
    key: 'date',
    text: t('searcher.table.date'),
    sortable: true,
    defaultSort: true,
    className: 'wideVisible',
  },
  {
    key: 'status',
    text: t('searcher.table.status'),
    style: { minWidth: 210 },
    className: 'mobileStatus',
  },
  {
    key: 'sources',
    text: t('searcher.table.sources'),
    style: { width: 200 },
    className: 'wideVisible',
  },
  {
    key: 'actions',
    text: t('searcher.table.actions'),
    style: { width: 110 },
  },
]

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export function SearcherPage() {

  //selectors
  const RulesAcknowledgement = useSelector(getRulesAcknowledgement)
  const user = useSelector((state) => state.settings)
  const lookups = useSelector(getLookups)

  //uses
  const dispatch = useDispatch()
  const tableClasses = useTableStyles()
  const cardClasses = useCardStyles()
  const theme = useTheme()
  const history = useHistory()
  const tableRef = useRef<TableRef>(null)
  useAuthEffect(() => {
    dispatch(fetchLookup)
  })

  //use state
  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = React.useState(false)
  const [deleteSingle, setDeleteSingle] = React.useState<SearchResult | null>(
    null,
  )
  const [deleteMultiple, setDeleteMultiple] = React.useState(false)
  const [currentLookup, setCurrentLookup] = React.useState<ILookup>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const openMenu = Boolean(anchorEl)

  const [isWelcomeDelayEnabled, setWelcomeDelayEnabled] = React.useState(false)
  const [open, setOpen] = React.useState(true)
  const [isWelcomePackageSearch, setWelcomePackageSorce] = React.useState(false)
 
  //helpers
  const fetchMaintenanceStatus = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/admin_settings/maintenance',
      })
      setIsMaintenanceEnabled(response.data)
    } catch (error) {
      console.error('Failed to fetch maintenance status:', error)
    }
  }
  
  const fetchWelcomeDelayStatus = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/admin_settings/welcomedelay',
      })
      setWelcomeDelayEnabled(response.data)
    } catch (error) {
      console.error('Failed to fetch maintenance status:', error)
    }
  }

  const syncAuthWithExtension = async () => {
    const extensionEnabled = process.env.REACT_APP_CROME_EXTENSION_ENABLED
    const extensionId = process.env.REACT_APP_CROME_EXTENSION_ID

    const user = await getFirebaseUser()

    if (extensionEnabled === 'true') {
      console.log('extensionId', extensionId, extensionEnabled)
      if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
        chrome.runtime.sendMessage(extensionId, { action: 'SignUp', data: { token: localStorage.TOKEN, refresh: user?.refreshToken} }, function(response) {
          if (response) {
            console.log('A response was received from the extension:', response)
          } else {
            console.log('No response received from chrome extension')
          }
        })
      } else {
        console.log('chrome.runtime.sendMessage is not available')
      }
    }
  }


  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    el: ILookup,
  ) => {
    setCurrentLookup(el)
    setAnchorEl(null)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect( () => {
    if (lookups?.length) {
      setCurrentLookup(lookups[0])
    }
  }, [lookups])


  //effects
  useEffect(() => {
    fetchMaintenanceStatus()
    fetchWelcomeDelayStatus()
    syncAuthWithExtension()
    if (user.uid) {
      visitPage(user.uid, "/searcher")
    }
  }, [])

  useEffect( () => {
    if (socket && user.id) {
      //console.log('call one', socket)
      socket.on("notification", (notification) => {
        const {notification_type, user_id} = notification
        if (
          user.id == user_id
          && notification_type === 'search_notification'
        ) {
          console.log('notification fired for user!', notification, user)
          tableRef.current?.refresh()
          dispatch(fetchProfile)
          //dispatch(fetchNotification(0, 30, false))
        }
        
        if (
          user.id == user_id
          && notification_type === 'free_package'
        ) {
          setWelcomePackageSorce(true)
        }
      })
      socket.on('no-results', (notification) => {
        const {user_id, message } = notification
        if (user.id == user_id && message){
          console.log('no results notification')
          dispatch(showSnackbar('error', message))
        }
      })
    }
    return () => {
      //console.log('socket off')
      socket && socket.off('notification')
    }
  }, [socket, user.id])

  function handleSubmit(
    e: React.FormEvent<HTMLFormElement>,
    data: ILookup,
    input: string,
    inputType: Source | undefined = undefined,
    country: string | undefined = undefined,
    inputIp: string | undefined = undefined,
  ) {
    e.preventDefault()

    const error = validateForm(schema, {
      type: data.type,
      input: input?.trim(),
      inputType: inputType,
    })

    //const error = false
    if (error) return error
    else {
      if (user.package?.type === 'demo') {
        goToPackages()
        return
      }
      setWelcomePackageSorce(false)
      switch (data.type) {
        case 'phone':
          const criteria = input.replace('+', '')
          dispatch(
            fetchTable(
              '/request-monitor',
              {
                offset: 0,
                limit: tableLimit,
              },
              'searcher',
            ),
          )
          dispatch(startLookup(data, criteria, undefined, tableLimit))
          break
        case 'name':
          dispatch(startLookup(data, input, '0', tableLimit))
          break
        case 'post':
          dispatch(startLookup(data, input, '0', tableLimit))
          break
        case 'deepweb':
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        case 'combined_phone':
          gtag('event', 'search')
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        case 'combined_email':
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        case 'combined_name':
          dispatch(startLookup(data, input, '0', tableLimit))
          break
        case 'combined_id':
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        case 'face_search': 
              if (input) {
                dispatch(startLookup(data, input, '0', tableLimit))
              }
          break
        case 'sentiment_analysys': 
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        case 'irbis_eye': 
          if (input) {
            dispatch(startLookup(data, input, '0', tableLimit))
          }
        break
        case 'photo_searcher':
          if (input) {
            dispatch(startLookup(data, input, '0', tableLimit))
          }
          break
        case 'kyc': {
            dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        }
        case 'ip_geo': {
            dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        }
        case 'web_collection': {
            dispatch(startLookup(data, input, '0', tableLimit, inputType))
          break
        }
        case 'name_by_country': {
          dispatch(startLookup(data, input, '0', tableLimit, inputType, country))
        break
        }
        case 'phone_list': {
          data.price = data.price * input.split("\n").length
          dispatch(startLookup(data, input, '0', tableLimit, inputType))
        break
        }
        case 'ipgeo_number': {
          dispatch(startLookup(data, input, '0', tableLimit, inputType, undefined, inputIp ))
        break
        }
        case 'psycho_profile': {
          dispatch(startLookup(data, input, '0', tableLimit, inputType ))
        break
        }

      }

      dispatch(hideSnackbar)
    }
  }

  function deleteCallback(ids: number[]) {
    tableRef.current?.refresh()
  }

  function handleDeleteSingle(row: SearchResult) {
    dispatch(deleteResults([row.id], () => deleteCallback([row.id])))
  }

  function handleDeleteMultiple() {
    const ids = tableRef.current?.getSelectedIds()
    if (ids) dispatch(deleteResults(ids, () => deleteCallback(ids)))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const goToPackages = () => {
    history.push({pathname: '/packages'} )
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }

  return (
    <PrivatePage>
      {
        user.package?.type === 'demo'
          ? <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              Greetings!
            </BootstrapDialogTitle>
            <DialogContent>
            <Divider variant="popup" color="primary" />
              <Typography >
              Thank you for subscribing to IRBIS! In order to perform your first search, please purchase one of the following packages:
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus variant="contained" size="large" onClick={goToPackages}>
              Choose packages
              </Button>
            </DialogActions>
          </BootstrapDialog>
          : <></>
      }

      <Box>
        <Card classes={cardClasses}>
          <Box
            position="absolute"
            top={0}
            left={0}
            width={10}
            height="100%"
            bgcolor={currentLookup ? currentLookup.color : '#000000'}
          />
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={12} xs={12}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    flexWrap: 'wrap',
                  },
                }}
              >
                <Typography
                  variant="title"
                  sx={{
                    flexShrink: '0',
                    fontSize: '16px',
                    [theme.breakpoints.down("xl")]: {
                      fontSize: '14px',
                    },
                    [theme.breakpoints.only("xs")]: {
                      flexShrink: '0',
                      width: '100%',
                    },
                }}
                >Select a workflow</Typography>
                <Typography
                  variant="text"
                  sx={{flexShrink: '0', fontSize: '12px', [theme.breakpoints.down("xl")]: {display: 'none'}}}
                >to start the search:</Typography>
                <List
                  component="nav"
                  aria-label="Device settings"
                  sx={{
                    bgcolor: 'background.paper',
                    height: '100%',
                    width: '100%',
                    [theme.breakpoints.only("xs")]: {
                      flexShrink: '0',
                      width: '100%',
                    },
                }}
                >
                  <ListItem
                    button
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClickListItem}
                    sx={{
                      height: '43px',
                      //borderBottom: '1px solid #8b929b52',
                      background: '#F5F8FF',
                    }}
                  >
                    <ListItemText
                      primary={
                        <Stack direction={'row'} gap={'15px'}>
                        {
                          (currentLookup?.price && !user.package?.creditsPerUnit) &&
                          <Typography
                            variant="bold"
                            color="primary"
                            sx={{
                              fontSize: '16px',
                              [theme.breakpoints.only("xs")]: {
                                fontSize: '14px',
                              },
                            }}
                          >€{currentLookup?.price}</Typography>
                        }
                        {
                          (!!currentLookup?.price && !!user.package?.creditsPerUnit) &&
                          <Typography
                            variant="bold"
                            color="primary"
                            sx={{
                              fontSize: '16px',
                              [theme.breakpoints.only("xs")]: {
                                fontSize: '14px',
                              },
                            }}
                          >Cred's: {(currentLookup?.price * user.package?.creditsPerUnit).toFixed(0)}</Typography>
                        }
                        <Typography
                          sx={{
                            display: 'block',
                            fontSize: '16px',
                            [theme.breakpoints.only("xs")]: {
                              fontSize: '14px',
                            },
                          }}
                          variant="title"
                        >{currentLookup?.title}</Typography>
                        </Stack>
                      }
                      //secondary={<Typography sx={{display: 'block', fontSize: '14px'}} variant="text">{currentLookup?.subTitle}</Typography>}
                    />
                    <ExpandMoreIcon />
                  </ListItem>
                </List>
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                  }}
                >
                  {
                    lookups
                      ?.filter(el => el.type !== 'webint' && el.type !== 'web_data_search' 
                        && el.type !== 'fb_person_wall_keyword' && el.type !== 'api_v2_instagram'
                         && el.type !== 'api_v2_linkedin' && el.type !== 'api_v2_x' && el.type !== 'sn_api_profiler' 
                          && el.type !== 'psycho_profile_summary' && el.type !== 'lookup_phone_verification'
                           && el.type !== 'lookup_phone_name_verification' && el.type !== 'real_phone' 
                            && el.type !== 'sn_api_profiler_fb_friends' && el.type !== 'sn_api_profiler_fb_posts' 
                            && el.type !== 'sn_api_profiler_insta_friends' && el.type !== 'sn_api_profiler_insta_posts'  )
                      ?.map((el, idx) => <MenuItem
                        key={idx}
                        selected={el.id === currentLookup?.id}
                        onClick={(event) => handleMenuItemClick(event, el)}
                      >
                        <Stack>
                          <Stack direction={'row'} spacing={2} sx={{alignItems: 'center'}}>
                            {
                              (el.price && !user.package?.creditsPerUnit) &&
                              <Typography variant="bold" color="primary" fontSize={16}>€{el.price}</Typography>
                            }
                            {
                              (!!el.price && !!user.package?.creditsPerUnit) &&
                              <Typography variant="bold" color="primary" fontSize={16}>Cred's: {(el.price * user.package?.creditsPerUnit).toFixed(0)}</Typography>
                            }
                            {/* <Typography variant="bold" color="primary" fontSize={14}>€{el.price}</Typography> */}
                            
                            <Typography fontSize={14}>{el.title}</Typography>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              alignItems={'center'}
                              spacing={1}
                              flexWrap="wrap"
                              ml={1}
                              sx={{
                                [theme.breakpoints.only("xs")]: {
                                  justifyContent: 'flex-start',
                                  margin: '10px 0',
                                  display: 'none',
                                },
                              }}
                            >
                              {
                                el.sources.slice(0, 5).map((source) => (
                                  <Box
                                    sx={{
                                      marginBottom: '5px !important',
                                      [theme.breakpoints.only("xs")]: {
                                        marginLeft: '2px!important',
                                        marginRight: '1px',
                                      },
                                    }}
                                    key={source}
                                    className={sprite_['social__small_' + source]}
                                  />
                                ))
                              }
                              {
                                el.sources.length > 5 && <Box
                                  sx={{
                                    marginBottom: '5px !important',
                                    [theme.breakpoints.only("xs")]: {
                                      marginLeft: '2px!important',
                                      marginRight: '1px',
                                    },
                                  }}
                                >
                                  ...
                                </Box>
                              }
                            </Stack>
                          </Stack>
                        </Stack>
                      </MenuItem>)
                  }
                </Menu>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={12} xs={12}>
              {
                currentLookup && <LookupInline index={currentLookup.id} data={currentLookup} onSubmit={handleSubmit} />
              }
            </Grid>
          </Grid>
        </Card>
      </Box>

      <DataTable
        ref={tableRef}
        id="searcher"
        url="/request-monitor"
        className={tableClasses.resultsCard}
        headerData={tableHeaderData}
        renderBody={(row, idx) => <RenderTableBody row={row} setDelete={(x: any) => setDeleteSingle(x)} />}
        title={t('searcher.table.title')}
        limit={tableLimit}
        pagination
        // refreshPerSeconds={10}
        selectable
        selectNav={
          <IconButton onClick={() => setDeleteMultiple(true)}>
            <DeleteIcon />
          </IconButton>
        }
        emptyText={
          <Typography variant="light" width={380} fontSize={18} align="center">
            {t('searcher.table.emptyText')}
          </Typography>
        }
        onRowClick={(row) => dispatch(toggleViewResult(row))}
      />

      {deleteSingle && (
        <PopupAsk
          onYes={() => handleDeleteSingle(deleteSingle)}
          onClose={() => setDeleteSingle(null)}
        />
      )}

      {deleteMultiple && (
        <PopupAsk
          onYes={handleDeleteMultiple}
          onClose={() => setDeleteMultiple(false)}
        />
      )}

      {RulesAcknowledgement && isWelcomeDelayEnabled && <Acknowledgement />}
      {isMaintenanceEnabled && <MaintenancePopup />}
      {isWelcomePackageSearch && <WelcomePackageRule />}
    </PrivatePage>
  )
}
