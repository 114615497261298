import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Close as CloseIcon,
} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  TextareaAutosize,
} from '@mui/material'
import { FormError } from '../../../component/FormError'
import { ErrorsState, select, validateForm } from '../../../function/validation'
import { useDispatch, useSelector } from '../../../store'
import { InputDropzone } from '../../../component/InputDropzone'
import { clearFieldError, showSnackbar } from '../../../store/common/actionCreator'
import { isValidPhoneNumber } from 'react-phone-number-input'

const schema = Joi.object({
  summary: Joi.string()
    .required()
    .label(t('field.label.text')),
  attachment: Joi.array(),
})

type Props = {
  onClose: () => void,
  onSetInput: (el: string) => void,
  input: string,
}

export const PopupPhoneListUpload = ({ onClose, input, onSetInput }: Props) => {
  const dispatch = useDispatch()

  const [errors, setErrors] = React.useState<ErrorsState>(null)
  const [isValid, setIsValid] = React.useState<Boolean>(true)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, input])

  function handleUpload(files: File[]) {
    const [file] = files
    setIsValid(true)
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      try {
        let content: string = event?.target?.result + ''
        const contentWords = content.split('\n')
        contentWords.forEach( (el : any, idx : number) => {
          const tempResult = el.replace(/[^\d+-]/g, '').replace("/n",'')
          if (!isValidPhoneNumber( tempResult ) && ( tempResult !== '') ) {
            content = ''
            dispatch(showSnackbar('error', `Wrong Phone Number in Line: ${idx+1} ( ${el} )`))
            if (tempResult !== '') {
            setIsValid(false)
            return
            }
          }
        } )
        onSetInput(content)
      } catch (e: any) {
        console.log('Parsing csv error', e.message)
        dispatch(showSnackbar('error', 'Parsing text file error'))
      }
    })
    reader.readAsText(file, "UTF-8")
  }

  function handleSubmit() {
    const error = validateForm(schema, {
      summary: input.trim(),
    })

    if (error) setErrors(error)
    else {
      setErrors(null)
      onClose()
    }
  }

  function handleClear() {
    setErrors(null)
    onSetInput('')
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>
        {t('searcher.phoneList.uploadText')}
        <IconButton className="button__close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2}>
        <FormControl>
          <FormLabel>Phone list:</FormLabel>
          <TextareaAutosize
            className={
              errors?.summary ? 'form__textarea_error' : 'form__textarea'
            }
            minRows={6}
            placeholder={t('field.hint.list')}
            value={input}
            disabled={true}
            onChange={
              (e) => {
                onSetInput(e.target.value)
              }
            }
          />
          <FormError error={errors?.summary} />
        </FormControl>
        {/** Attachment */}
        <FormControl>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormLabel>{t('searcher.phoneList.attachment')}</FormLabel>
          </Stack>
          <InputDropzone
            onUpload={handleUpload}
            imagesOnly={false}
            maxFiles={1}
            multiple={false}
            sizeLimit={1024 * 500}
          />
          <FormError error={errors?.attachment} />
        </FormControl>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions sx={{ justifyContent: 'right' }}>
        <Button variant="outlined" onClick={handleClear}>
          {t('searcher.phoneList.clear')}
        </Button>
        <Button variant="contained" disabled={isValid ? false : true} color="primary" onClick={handleSubmit}>
          {t('searcher.phoneList.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}