import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Divider, Fade, Grid, IconButton,
  Menu,
  MenuItem, Paper,
  Stack, StyledEngineProvider, Tooltip,
  Typography, Unstable_TrapFocus,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import { signOut } from '../../store/auth/actionCreator'
import { Avatar } from '../../component/Avatar'
import { Notification } from './Notification'
import { useUserPackage } from '../../hook/useUserPackage'
import { MenuOpen as MenuIcon } from '@mui/icons-material'
import { postQuota } from '../../store/webint/actionCreator'
import quotaprofiler_lookup from '../../../src/asset/icon/quotaprofiler-lookup.png'
import quotaprofiler_crawler from '../../../src/asset/icon/quotaprofiler-crawler.png'
import quotaprofiler_cases from '../../../src/asset/icon/quotaprofiler-cases.png'
import quotawebint_cases from '../../../src/asset/icon/quotawebint-cases.png'
import quotawebint_crawler from '../../../src/asset/icon/quotawebint-crawler.png'
import quotawebint_monitoring from '../../../src/asset/icon/quotawebint-monitoring.png'
import { differenceInSeconds, format } from 'date-fns'
import { fetchPackage } from '../../store/package/actionCreator'
import { PackageBadge } from '../../component/PackageBadge'
import moment from 'moment'
import { SettingsState } from '../../store/settings/reducer'
import { StyledPackage } from '../../style/package'
import { PopupMobile } from '../../component/PopupMobile'

function UserProfile() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [anchorAvatar, setAnchorAvatar] = React.useState<null | HTMLElement>(
    null,
  )

  function handleSettingsClick() {
    setAnchorAvatar(null)
    history.push('/settings')
  }

  function handleSignOutClick() {
    setAnchorAvatar(null)
    dispatch(signOut)
  }

  return (
    <>
      <Box onClick={(e) => setAnchorAvatar(e.currentTarget)}>
        <Avatar size="small" />
      </Box>

      <Menu
        anchorEl={anchorAvatar}
        open={Boolean(anchorAvatar)}
        onClose={() => setAnchorAvatar(null)}
        keepMounted
      >
        <MenuItem onClick={handleSettingsClick}>
          {t('header.editProfile')}
        </MenuItem>
        <MenuItem onClick={handleSignOutClick}>{t('header.logout')}</MenuItem>
      </Menu>
    </>
  )
}

type Props = {
  setNavbarOpen: any,
  isMobile: boolean | undefined,
}

export function Header({ setNavbarOpen, isMobile }: Props) {
  
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { daysLeft, isExpired, additionalDaysLeft, additionalIsExpired } = useUserPackage()

  const { packageBalance } = useSelector((state) => state.settings)

  const { quota }  = useSelector( (state) => state.userQuota)
  
  const user = useSelector((state) => state.settings)

  const { packages } = useSelector((state) => state.package)

  const [irbisProMode, setIrbisProMode] = React.useState<boolean>(false)
  const [showMobilePopup, setShowMobilePopup] = React.useState(!localStorage.getItem('mobilePopup'))

  function handleRedirectCustomOnly() {
    //history.push({pathname: '/packages', state: { show: 'general' }} )
    history.push({pathname: '/packages/general'} )
  }

  function handleRedirectWebintOnly() {
    //history.push({pathname: '/packages', state: { show: 'webinthub' }} )
    history.push({pathname: '/packages/webinthub'} )
  }

  function handleClosePopupMobile() {
    console.log('close popup')
    setShowMobilePopup(false)
    localStorage.setItem('mobilePopup', '1')
  }

  const showWebintOrSubpackage = (dateTime : Date | null) : string => {
    let someDate : Date = new Date()
    const date = new Date()
    if (dateTime) {
    someDate = new Date(dateTime)
    } else {
      return 'webinthub'
    }
    
    if (someDate) {
      if (date.valueOf() - someDate.valueOf() > 0 ) {
        return 'webinthub'
      } else {
        if (user.additionalPackageType === 'webinthub_demo') {
          return 'webinthub'
        } else {
        return 'webinthub_subpackage'
        }
      }
    } else {
      return 'webinthub'
    }
  }
  const showWebintAdvanced = () => {
    //console.log(" user.irbisproUpgrade = ", user)
    if ( (user.irbisproUpgrade !== true && (showWebintOrSubpackage(user.additionalPackageEndDate) === 'webinthub_subpackage'))) {
      return 'webinthub_upgrade'
    }
  }

  function removeZerosAfterPoint(theNumber: number){
    //const toDisplay = theNumber.toFixed(2)
    const toDisplay = theNumber.toFixed(2)
    const toRemove = (toDisplay.endsWith(".00") ? 3 : (toDisplay.endsWith("0") ? 1 : 0))
    return toDisplay.substring(0, toDisplay.length - toRemove)
  }
  
  function displayPackageBalanceOrZero(user: SettingsState, packageBalance: number|null, isExpired: boolean){
    // if expired there's no reason to continue checking everything else
    if (isExpired){
      return '0'
    }
    if (typeof packageBalance === 'number' && packageBalance > 0){
      if (packageBalance && user && user.package && !user.package.creditsPerUnit){
        return removeZerosAfterPoint(packageBalance)
      }
      return ((packageBalance * (user.package?.creditsPerUnit ? user.package.creditsPerUnit : 1))).toFixed(0)
    }
    else return '0'
  }

  // function displayAnnualBonus(user: SettingsState){
  //   if (user.package?.creditsPerUnit && user.annualBonus){
  //     //return `+${removeZerosAfterPoint((user.annualBonus * user.package.creditsPerUnit))} bonus credits`
  //     return ''
  //   }else if(user.annualBonus){
  //     return `+€${removeZerosAfterPoint(user.annualBonus)}`
  //   }else return ''
  // }
  
  function countPackageTime() {
    if (user.packageStartDate && user.packageEndDate) {
      const date1 = new Date()
      const date2 = new Date(user.packageEndDate)
      const totalseconds = differenceInSeconds(date2, date1)
      if (totalseconds > 0) {
      const day = 86400
      const hour = 3600
      const minute = 60
      const daysout = Math.floor(totalseconds / day)
      const hoursout = Math.floor((totalseconds - daysout * day)/hour)
      const minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour)/minute)
      const secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute
      
      const resHours = hoursout < 10 ? "0"+hoursout : hoursout
      const resMin = minutesout < 10 ? "0"+minutesout : minutesout
      const resSec = secondsout < 10 ? "0"+secondsout : secondsout

      return `${resHours+":"+resMin+":"+resSec}`
      } else {
        return daysLeft + " days"
      }
        } else {
        return daysLeft + " days"
      }
   }

  function countIrbisProTime() {
    if (user.additionalPackageId && user.additionalPackageEndDate) {
      if (moment() < moment(user.additionalPackageStartDate)) {
        return "Buy package"
      }
      if (moment() > moment(user.additionalPackageEndDate)) {
        return "Expired. Click to renew"
      }
      return `${moment(user.additionalPackageEndDate).diff(moment(), 'days')} day(s)`
    } else {
      return "Buy package"
    }
  }

  //effects
  React.useEffect(() => {
    if (user.uid) {
      dispatch(postQuota({userId: user.uid}))
      dispatch(fetchPackage)
    }
  }, [user.uid] )

  React.useEffect(() => {
    if (pathname && pathname.indexOf('irbispro') > -1) {
      setIrbisProMode(true)
    }
  }, [pathname] )

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        bgcolor="#FFF"
        p={2}
        spacing={2}
      >
        {
          isMobile && <Grid
            className="menu"
            container
            justifyContent="space-between"
            alignItems="center"
            style={{padding: 0, height: 'inherit'}}
          >
            <Grid item>
              <IconButton
                onClick={() => setNavbarOpen(true)}
              >
                <MenuIcon style={{ transform: 'scaleX(-1)' }} />
              </IconButton>
            </Grid>
          </Grid>
        }
        {
          !isMobile && <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
            {
              !irbisProMode && packages
                ?.filter(el =>
                  (el.type !== 'telegrambt' && el.type === showWebintOrSubpackage(user.additionalPackageEndDate)) ||
                  (el.type === 'general' || /*el.type === 'custom' ||*/ el.type === 'personal' || el.type === showWebintAdvanced()),
                )
                ?.filter(el => !el.isFree)
                ?.filter(el => el?.isActive)
                ?.filter(el => el?.type?.indexOf('webinthub') === -1)
                ?.filter(el => (el.price >= (user?.package?.price || 0) /*|| el.type === 'custom'*/))
                ?.map((el) => <PackageBadge
                  data={el}
                  index={el.id}
                  key={el.id}
                  packagesQuantity={packages.length}
                  //setPurchaseOpen={setPurchaseOpen}
                />)
            }
            {
              irbisProMode && packages
                /*?.filter(el =>
                  (el.type !== 'telegrambt' && el.type === showWebintOrSubpackage(user.additionalPackageEndDate)) ||
                  (el.type === showWebintAdvanced()),
                )*/
                //?.filter(el => el?.isActive)
                ?.filter( el => (el.isActive && (el.type === showWebintOrSubpackage(user.additionalPackageEndDate) || el.type === showWebintAdvanced())))
                //?.filter(el => el?.type?.indexOf('webinthub') > -1)
                //?.filter(el => (el.price >= (user?.package?.price || 0) || el.type === 'custom'))
                ?.map((el) => <PackageBadge
                  data={el}
                  index={el.id}
                  key={el.id}
                  packagesQuantity={packages.length}
                  //setPurchaseOpen={setPurchaseOpen}
                />)
            }
          </Stack>
        }
        {
          //current user's package
          user.package && !irbisProMode && <>
            <StyledEngineProvider injectFirst={true}>
              <Stack direction={'row'} gap={2}>
                {
                  !isMobile && <Box sx={{position: 'relative'}}>
                    {
                      !irbisProMode && packages
                        ?.filter(el => el.type === 'custom')
                        ?.map((el) => <PackageBadge
                          data={el}
                          index={el.id}
                          key={el.id}
                          packagesQuantity={packages.length}
                          //setPurchaseOpen={setPurchaseOpen}
                        />)
                    }
                  </Box>
                }
                <StyledPackage {...{isActive: true, isShort: true, isMobile}} onClick={handleRedirectCustomOnly}>
                  <Stack className={'package__header'} direction={'row'}>
                    <Box sx={{position: 'relative'}}>
                      {
                        !user.package?.creditsPerUnit && <Typography className={'package__currency'}>€</Typography>
                      }
                      {
                        user.package?.creditsPerUnit > 0 && <Typography className={'package__currency'} sx={{fontSize: '14px!important'}}>Cred's</Typography>
                      }
                      <Typography className={'package__price'}>{displayPackageBalanceOrZero(user, packageBalance, isExpired)}</Typography>
                      {
                        !isMobile && <Box className={'package__balance'}>
                          <Typography className={'package__balance_text'}>Balance</Typography>
                        </Box>
                      }
                    </Box>
                    {
                      !isMobile && <Box className={'package__divider'} />
                    }
                    <Stack direction={'column'} className={'package__name'}>
                      <Tooltip title={user.package?.type === 'demo' ? 'DEMO' : user.package?.title}><Typography noWrap={true} className={'package__title'}>
                        {user.package?.type === 'demo' && 'DEMO'}
                        {user.package?.type !== 'demo' && user.package?.title}
                      </Typography></Tooltip>
                      <Typography className={'package__title package__subtitle'}>{daysLeft === 0 ? countPackageTime() : daysLeft + ' days' } {' '}</Typography>
                    </Stack>
                  </Stack>
                </StyledPackage>
              </Stack>
            </StyledEngineProvider>
          </>
        }
        {
          !isMobile && irbisProMode &&
          <Stack direction={'row'} gap={2}>
            {
              packages
                ?.filter((el) => (el.type === 'custom'))?.map((el) =>
                <PackageBadge
                  data={el}
                  index={el.id}
                  key={el.id}
                  packagesQuantity={packages.length}
                />)
            }
            {
              irbisProMode && <Box
                sx={{
                  height: '72px',
                  width: '180px',
                  borderRadius: '15px',
                  background: '#03173D',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={handleRedirectWebintOnly}
              >
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{padding: '10px'}}>
                  <Typography fontSize={'24px'} fontWeight={700} color={'white'} noWrap={true} sx={{overflow: 'hidden'}}>Irbis PRO</Typography>
                  <Typography fontSize={'14px'} fontWeight={400} color={'white'} noWrap={true} lineHeight={1}>{countIrbisProTime()}</Typography>
                </Stack>
              </Box>
            }
          </Stack>
        }
      </Stack>
      {
        isMobile && showMobilePopup && <PopupMobile onClose={handleClosePopupMobile} />
      }
    </>
  )
}
