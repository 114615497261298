import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material'

export const ResultCriteria = styled('div')({
  display: 'flex',
  background: '#FFFFFF',
  border: '1px solid #A4A4A4',
  borderRadius: '20px',
  marginLeft: '20px',
  height: '54px',
  padding: '12px 25px',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '22px',
  color: '#01163E',
  flexWrap: 'nowrap',
})

export const ResultCriteriaTitle = styled('div')({
  maxWidth: '400px',
  whiteSpace: 'nowrap',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '22px',
  color: '#01163E',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '5px 0',
})

export const ResultName = styled('div')({
  display: 'flex',
  background: '#40A0D9',
  borderRadius: '10px',
  height: '52px',
  padding: '12px 25px',
  justifyContent: 'flex-start',
  alignItems: 'center',

  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '22px',
  color: '#FFFFFF',
})

export const ResultNameTitle = styled('div')({
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '22px',
  color: '#FFFFFF',
  maxWidth: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '2px 0',
  display: 'flex',
  gap: '10px',
})

export const ResultVerified = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'none',
  border: '2px solid #4BDE97',
  borderRadius: '10px',
  height: '32px',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '15px',

  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#4BDE97',
  minWidth: '120px',
  [theme.breakpoints.down("md")]: {
    fontSize: '12px',
    //height: '22px',
    minWidth: '120px',
    marginTop: '10px',
  },
}))

export const ResultNotVerified = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'none',
  border: '2px solid #de6d4b',
  borderRadius: '10px',
  height: '32px',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '15px',

  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#de6d4b',
  minWidth: '150px',

  [theme.breakpoints.down("md")]: {
    fontSize: '12px',
    //height: '22px',
    minWidth: '150px',
    marginTop: '10px',
  },
}))

export const ResultTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'none',
  border: '1px solid #40A0D9',
  borderRadius: '10px',
  width: 'fit-content',
  height: '34px',
  padding: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',

  fontWeight: 500,
  fontSize: '22px',
  lineHeight: '14px',
  color: '#40A0D9',
  [theme.breakpoints.only("xs")]: {
    fontSize: '16px',
  },
}))

export const ResultPshychoTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'none',
  border: '1px solid #40A0D9',
  borderRadius: '10px',
  width: 'fit-content',
  height: '34px',
  padding: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',

  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '14px',
  color: '#40A0D9',
  [theme.breakpoints.only("xs")]: {
    fontSize: '16px',
  },
}))

export const ExportButton = styled(Button)({
  border: '1px solid #A4A4A4',
  borderRadius: '20px',
  textTransform: 'uppercase',
  height: '44px',

  '&:hover': {

  },
  '&:active': {

  },
})

interface CompareButtonProps {
  isActive?: boolean,
}
export const CompareButton = styled(Button)(({isActive = false}: CompareButtonProps) => ({
  width: '122px',
  height: '30px',
  textAlign: 'center',
  padding: '3px 20px',
  background: isActive ? '#96CBEA' : '#2E97D5',
  borderRadius: '4px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '24px',
  color: '#FFFFFF',

  '&:hover': {
    background: isActive ? '#96CBEA' : '#2E97D5',
    opacity: 0.6,
  },
  '&:active': {
    background: isActive ? '#96CBEA' : '#2E97D5',
    opacity: 0.6,
  },
}))

interface ResultEntityProps {
  theme: Theme,
  sentiment?: 'neutral' | 'negative' | 'positive' | '',
}
export const ResultEntity = styled('div')(({ theme, sentiment = 'neutral' }: ResultEntityProps) => ({
  display: 'flex',
  background: 'none',
  border: `1px solid ${(() => {
    switch (sentiment) {
      case 'positive':
        return "#ABE188"
      case 'negative':
        return "#EF6352"
      default:
        return "#3994DB"
    }
  })()}`,
  borderRadius: '10px',
  height: '32px',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  //marginRight: '15px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexWrap: 'nowrap',

  fontWeight: 600,
  fontSize: '16px',
  //lineHeight: '24px',
  color: (() => {
    switch (sentiment) {
      case 'positive':
        return "#ABE188"
      case 'negative':
        return "#EF6352"
      default:
        return "#3994DB"
    }
  })(),
  //minWidth: '150px',

  [theme.breakpoints.down("md")]: {
    fontSize: '12px',
    height: '22px',
    minWidth: '150px',
  },
}))