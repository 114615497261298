import React from 'react'
import { useTranslation } from 'react-i18next'
import { LocalData } from '../../../component/LocalData'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { format } from 'date-fns'
import { store } from '../../../store'
import {
  Box,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useTheme,
} from '@mui/material'
import sprite_ from '../../../sass/sprite.module.sass'
import { useAccordionStyles } from '../../../style/lookup'
import { PhoneDataItem, Phone, PhoneLookupResult, SearchResult } from '../../../store/searcher/reducer'
import {
  getLabel,
  getObjectValue, RenderImage,
} from '../../../component/searcher/RenderData'
import { fromUnix } from '../../../function/date'
import { isNumeric } from '../../../function/number'
import { CommonState } from '../../../store/common/reducer'
import { preparePhoneResult } from '../../../store/searcher/service'
import { validateDataHasMoreFields, validateGetContactObject, validateHiyaObject, validateLunaObject, validateWhatsappObject } from '../../../function/object-validator'
import { convertToRealName, returnLinkToSocialNetwork } from '../../../function/icons'
import { Link } from 'react-router-dom'
import { string } from 'joi'
import { getLinkToProfile, getTitle, isHttpUrl } from '../../../function/string'
import TaroItem from './items/TaroItem'
import WebCollectionEmailItem from './items/WebCollectionEmailItem'
import LunaItem from './items/LunaItem'
import EyesItem from './items/EyesItem'
import CallappItem from './items/CallappItem'


const apps: Phone[] = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'tamtam',
  'botim',
  'imo',
  'hiya',
]
export const necessaryData = [
  'email',
  'city',
  'country',
  'createdTime',
  'name',
  'criteria',
  'online',
  'status',
  'avatar',
  'displayName',
  'displayDetail',
  'facebookID',
  'vkID',
  'twitterScreenName',
  'userDefinition',
  //'googlePlusID',
  //'isFailed',
  'carrier',
]

export interface Name {
  app: Phone
  name: string
}

export interface LastOnline {
  app: Phone
  online: any
}
export interface Birthday {
  app: Phone
  birthday: string
}

export interface Images {
  app: Phone
  image: string
}

export interface SocialNetwork {
  app: string
  socialNetwork : any
}

function getPreview(data: PhoneDataItem[] | undefined) {
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const noNeedInFormatDate = ['Offline', 'Online']

  const images: Images[] = []
  const lastOnline: LastOnline[] = []
  const names: Name[] = []
  const birthday: Birthday[] = []
  const allSocialNetworks: SocialNetwork[] = []

  data?.filter((d: any) => apps.includes(d.from)).forEach((el) => {
    apps.forEach((app) => {
      if (el.data[app]) {

        if (app === 'skype') {
          const baseUrl = el.data[app].avatar?.split('?')[0]
          const urlParams = new URLSearchParams(el.data[app].avatar?.split('?')[1])
          if (urlParams && baseUrl && (urlParams.get('returnDefaultImage') == "false" )) {
            urlParams.set('returnDefaultImage', 'true')
            el.data[app].avatar =  baseUrl + '?' + urlParams
          }
        }

        const avatar: string | undefined = getObjectValue(
          el.data[app],
          'avatar',
        )
        const name: string | undefined = getObjectValue(el.data[app], 'name')
        const name2: string | undefined = getObjectValue(
          el.data[app],
          'nickname',
        )
        let online_unix: number | string | undefined = getObjectValue(
          el.data[app],
          'online',
        )
        let online2_unix: string | undefined | 'Recently' = getObjectValue(
          el.data[app],
          'last_seen',
        )
        const online3_unix: number | undefined = getObjectValue(
          el.data[app],
          'lastUpdateTime',
        )
        const _birthday = getObjectValue(el.data[app], 'birthday')

        const image: string | undefined = getObjectValue(el.data[app], 'image')
        if (image) {
          images.push({ app: app, image: image })
        }
        const photoUrl: string | undefined = getObjectValue(
          el.data[app],
          'photoUrl',
        )
        if (photoUrl) {
          images.push({ app: app, image: photoUrl })
        }
        if (app === 'getcontact') {
          const imageGetContact = el?.data?.getcontact?.photo
          if (imageGetContact) images.push({ app: app, image: imageGetContact })
        }

        if (typeof _birthday === 'string' && _birthday) {
          birthday.push({ app: app, birthday: _birthday })
        }

        if (_birthday && app === 'callapp' && _birthday.formattedYear) {
          birthday.push({
            app: app,
            birthday:
              _birthday?.formattedYear +
              '-' +
              _birthday?.formattedMonth +
              '-' +
              _birthday?.formattedDay,
          })
        }

        const _socialNetwork = getObjectValue(el.data[app], 'socialNetworkIds')
        if (_socialNetwork && app === 'callapp') {
          for (const k in _socialNetwork) {
            allSocialNetworks.push({app: k, socialNetwork: _socialNetwork[k].id})
          }

        }

        if (avatar) {
          images.push({ app: app, image: avatar })
        }

        if (name) {
          names.push({ app: app, name: name })
        }

        if (name2) {
          if (app !== 'telegram') {
            names.push({ app: app, name: name2 })
          }
        }
        if (app === 'tamtam') {
          const name = getObjectValue(el?.data[app], 'names')
          if (name) {
            names.push({ app: app, name: name[1]?.name })
          }
        }
        if (online_unix) {
          if (isNumeric(String(online_unix))) {
            online_unix = format(
              new Date(online_unix),
              `dd/MM/yyyy ${timeFormat}`,
            )
          }

          lastOnline.push({
            app: app,
            online: online_unix,
          })
        }

        if (online2_unix) {
          if (
            !noNeedInFormatDate.includes(online2_unix) &&
            online2_unix !== 'Recently'
          ) {
            if (online2_unix === 'LastWeek' || online2_unix === 'LastMonth') {
              switch (online2_unix) {
                case 'LastWeek':
                  online2_unix = 'Last Week'
                  break
                case 'LastMonth':
                  online2_unix = 'Last Month'
                  break
              }
            } else {
              online2_unix = format(
                fromUnix(Number(online2_unix)),
                `dd/MM/yyyy ${timeFormat}`,
              )
            }
          }
          if (app !== 'tamtam') {
            lastOnline.push({ app: app, online: online2_unix })
          }
        }

        if (online3_unix) {
          lastOnline.push({
            app: app,
            online: format(fromUnix(online3_unix), `dd/MM/yyyy ${timeFormat}`),
          })
        }
      }
    })
  })

  return { images, lastOnline, names, birthday, allSocialNetworks }
}

type Props = {
  apiData: SearchResult
}

export const PhoneCommon = (props: Props) => {
  const { t } = useTranslation()
  const accordionClasses: any = useAccordionStyles()
  const regionNames = new Intl.DisplayNames(['EN'], { type: 'region' })
  const {apiData} = props
  const data = preparePhoneResult(apiData)
  const theme = useTheme()
  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_) => false) : []
  }, [data.data])

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)

  const { allSocialNetworks } = getPreview(data.data)

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
  }

  const countNeededElements = (dataEl : any, elements : any) => {
    let res : any = 0
    elements.map( (element : any) => {
      const value = getObjectValue(dataEl, element) || '-'
      if (value !== '-') {
        res = res + 1
      }
    })
    return res
  }

  const checkLmObject = (el : PhoneDataItem) => {
    if ( Object.keys(el.data[el.from] ).length === 0 ) {
      return <Typography variant="bold" align='center'>No results</Typography>
    } else {
      if (el.from === 'whatsapp') {
        return validateWhatsappObject(el) ? <Typography variant="bold" align='center'>No results</Typography> : false
      }
      if (el.from === 'hiya') {
        return validateHiyaObject(el) ? <Typography variant="bold" align='center'>No results</Typography> : false
      }

    }
    return <LocalData dataEl={el} />
  }

  const checkLmObjectDisplay = (el: PhoneDataItem) => {
    if (!el.data) {
      return false
    }
    if ( Object.keys(el.data[el.from] || {} ).length === 0 ) {
      return false
    } else {
      if (el.from === 'whatsapp') {
        return !validateWhatsappObject(el)
      }
      if (el.from === 'hiya') {
        return !validateHiyaObject(el)
      }
      if (el.from === 'luna') {
        return !validateLunaObject(el)
      }
      if (el.from === 'taro') {
        return !(Object.keys(el.data[el.from]['data'] || {} ).length === 0)
      }
      if (el.from === 'getcontact'){
        return validateGetContactObject(el)
      }
      if (!["web_collection_email","web_collection_phone","ip"].includes(el.from)){
        return validateDataHasMoreFields(el)
      }
    }
    return true
  }

  const returnCallAppData = (namesAndIds: SocialNetwork[]) => {
    if (namesAndIds) {
      return namesAndIds.map( el => {
        if (convertToRealName(el.app) !== "")
          return <>
            <Grid item xs={6} >
              <Grid container={el.socialNetwork.length < 35}  sx={{display: 'flex'}}>
                <Grid item xs={4}>
                  <Typography variant="text" fontSize={14}>
                    {convertToRealName(el.app) + " S/N:"}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Link to={ { pathname: returnLinkToSocialNetwork(el.app, el.socialNetwork)}} target="_blank">
                    <Typography variant="bold" fontSize={14}>{el.socialNetwork}</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </>
      })
    } else {
      return null
    }
  }

  //console.log('DATA', data?.data?.filter((el) => el.from !== 'taro'))

  return (
    <Stack direction="row" alignItems="flex-start" spacing={7} sx={{
      [theme.breakpoints.only("xs")]: {
        flexDirection: 'column',
      },
    }}>
      <Stack sx={{
        [theme.breakpoints.only("xs")]: {
          marginLeft: '0!important',
          marginTop: '10px!important',
        },
        width: '100%',
      }}>

        {data?.data
          ?.filter((el) => !['web_collection'].includes(el.from))
          ?.filter((el) => el.from !== 'ip' && checkLmObjectDisplay(el))
          .map((dataEl, idx) => (

            <Accordion
              classes={accordionClasses}
              key={idx}
              square
              expanded={accordionOpen[idx]}
              onChange={handleChangeAccordion(idx)}
              sx={{width: '100%'}}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{width: '100%'}}>
                <Stack direction="row" alignItems="center" spacing={1} sx={{width: '100%'}}>
                  <Box
                    className={sprite_['social__small_' + dataEl.from]}
                  />
                  <Typography
                    variant="semiBold"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {getTitle(dataEl.from)}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Divider
                  sx={{
                    margin: '-5px 0px 15px 0px',
                    border: `0.1px solid rgba(46, 151, 213, 0.3)`,
                  }}
                />

                <Grid container sx={{overflow: 'hidden'}}>
                  { (countNeededElements(dataEl, necessaryData) > 2) ?
                    necessaryData.map((el) => {
                      let label = el
                      switch (el) {
                        case 'online':
                          label = 'last_online'
                          break
                        case 'criteria':
                          label = 'phone'
                          break
                        case 'createdTime':
                          label = 'created'
                          break
                        case 'email':
                          label = 'emails'
                          break
                      }
                      let value = getObjectValue(dataEl, el) || '-'

                      if (dataEl?.from === 'callapp') {
                        if (el === 'email') {
                          value = '-'
                        }
                        /*if (
                          el === 'email' &&
                          //getObjectValue(dataEl, el) === undefined &&
                          value !== '-'
                        ) {
                          //value = getObjectValue(dataEl, 'emails')?.email || '-'
                          const emailsArr: string[] = []
                          //console.log('dataEl?.data?.[dataEl?.from]?.emails', dataEl?.data?.[dataEl?.from]?.emails)

                          if (dataEl?.data?.[dataEl?.from]?.emails && Array.isArray(dataEl?.data?.[dataEl?.from]?.emails)) {
                            dataEl?.data?.[dataEl?.from]?.emails?.forEach((email: any) => {
                              emailsArr.push(email.email)
                            })
                            value = emailsArr.join("\n\r")
                          } else if (dataEl?.data?.[dataEl?.from]?.emails && typeof dataEl?.data?.[dataEl?.from]?.emails === 'object') {
                            Object.entries(dataEl?.data?.[dataEl?.from]?.emails).map(([key, email]) => {
                              const em = email as any
                              emailsArr.push(em?.email)
                            })
                            value = emailsArr.join("\n\r")
                          }
                        }*/

                        if (typeof value === 'object') {
                          if (value?.id) {
                            value = getLinkToProfile(el, value?.id)
                          }
                        }
                      }


                      if (
                        dataEl.from === 'tc' &&
                        el === 'email' &&
                        getObjectValue(dataEl, el) === undefined
                      ) {
                        value =
                          getObjectValue(dataEl, 'internetAddresses')?.[0]
                            ?.id || '-'
                      }

                      if (
                        (dataEl.from === 'getcontact' ||
                          dataEl.from === 'tc') &&
                        el === 'country' &&
                        // eslint-disable-next-line
                        getObjectValue(dataEl, el) != 1 &&
                        // eslint-disable-next-line
                        getObjectValue(dataEl, 'countryCode') !== undefined
                      ) {
                        value =
                          regionNames.of(
                            getObjectValue(dataEl, 'countryCode'),
                          ) || '-'
                      }

                      //console.log('dataEl.from', dataEl.from)
                      if (dataEl.from === 'luna') {
                        //console.log('el', el, dataEl)
                        if (el === 'name') {
                          if (dataEl?.data?.[dataEl?.from]?.person?.names && Array.isArray(dataEl?.data?.[dataEl?.from]?.person?.names)) {
                            const namesArr: string[] = []
                            dataEl?.data?.[dataEl?.from]?.person?.names?.forEach((name: any) => {
                              namesArr.push(name.display)
                            })
                            value = namesArr.join("\n\r")
                          }
                        }
                        if (el === 'email') {
                          if (dataEl?.data?.[dataEl?.from]?.person?.emails && Array.isArray(dataEl?.data?.[dataEl?.from]?.person?.emails)) {
                            const emailsArr: string[] = []
                            dataEl?.data?.[dataEl?.from]?.person?.emails?.forEach((email: any) => {
                              emailsArr.push(email.address)
                            })
                            value = emailsArr.join("\n\r")
                          }
                        }
                        if (el === 'country') {
                          if (dataEl?.data?.[dataEl?.from]?.person?.addresses && Array.isArray(dataEl?.data?.[dataEl?.from]?.person?.addresses)) {
                            const addressArr: string[] = []
                            dataEl?.data?.[dataEl?.from]?.person?.addresses?.forEach((address: any) => {
                              addressArr.push(address.display)
                            })
                            value = addressArr.join("\n\r")
                          }
                        }
                        if (el === 'avatar') {
                          if (dataEl?.data?.[dataEl?.from]?.sources && Array.isArray(dataEl?.data?.[dataEl?.from]?.sources)) {
                            const avatarSource = dataEl?.data?.[dataEl?.from]?.sources
                              .find((source: any) => source.category === "personal_profiles" && source?.images?.length > 0)
                            if (avatarSource) {
                              value = avatarSource?.images[0]?.url
                            }
                          }
                        }
                        if (el === 'criteria') {
                          if (dataEl?.data?.[dataEl?.from]?.person?.phones && Array.isArray(dataEl?.data?.[dataEl?.from]?.person?.phones)) {
                            const phonesArr: string[] = []
                            dataEl?.data?.[dataEl?.from]?.person?.phones?.forEach((phone: any) => {
                              phonesArr.push(phone.displayInternational)
                            })
                            value = phonesArr.join("\n\r")
                          }
                        }
                      }

                      if (['taro', 'web_collection_email', 'web_collection_phone', 'eyes'].includes(dataEl.from)) {
                        value = '-'
                      }

                      if ( value !== '-') {
                        return (
                          <Grid item xs={6} key={el}>
                            <Grid container={value.length < 35} sx={{display: 'flex', overflow: 'hidden'}}>
                              <Grid item xs={4}>
                                <Typography variant="text" fontSize={14} sx={{ wordBreak: 'break-all'}}>
                                  {getLabel(label)}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                {
                                  label === 'avatar'
                                    ? <Box sx={{width: '50px', height: '50px'}}><RenderImage _key={'value'} imgHeight={50} val={value} /></Box>
                                    : isHttpUrl(value)
                                        ? <a target="_blank" href={value}>open</a>
                                        : <Typography variant="bold" sx={{ wordBreak: 'break-all', whiteSpace: "pre-line" }}>{value}</Typography>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      } else {
                        return null
                      }
                    }) :  null

                  }
                  {checkLmObject(dataEl)}
                  { dataEl?.from === 'callapp' ? <>{returnCallAppData(allSocialNetworks)}<CallappItem el={dataEl} /></> : null}
                  { dataEl?.from === 'taro' ? <TaroItem el={dataEl} /> : null }
                  { dataEl?.from === 'web_collection_email' && <WebCollectionEmailItem el={dataEl} />}
                  { dataEl?.from === 'web_collection_phone' && <WebCollectionEmailItem el={dataEl} />} 
                  { dataEl?.from === 'luna' && <LunaItem el={dataEl} />}
                  { dataEl?.from === 'eyes' && <EyesItem el={dataEl} />}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </Stack>
    </Stack>
  )
}