import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { PhoneDataItem, SearchResult } from '../../../../store/searcher/reducer'
import { getLabel } from '../../../../component/searcher/RenderData'

type Props = {
  el?: any
}
type objType = {
  [key: string]: object;
};

const WebCollectionEmailItem = (props: Props) => {
  const {el} = props
  //console.log('TaroDATa', el.data[el.from])
  const webCollectionItem = el.data[el.from]
  if (webCollectionItem) {
    return <>
      {
        Object.entries(webCollectionItem).map(([service, serviceData]: [string, any]) =>
          <Grid item xs={12} >
            <Grid container sx={{display: 'flex'}}>
              <Grid item xs={2}>
                <Typography variant="text" fontSize={14}>{getLabel(service)}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {
                    typeof serviceData === 'string'
                      ? <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{serviceData}</Typography>
                      : (serviceData.text && <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{serviceData.text + ''}</Typography>)
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>,
        )
      }
    </>
  }
  return null
}

export default WebCollectionEmailItem