import { SocialNetwoksRealNames, SocialNetworkLinks, SocialNetworkNames } from '../enum/provider'

export const returnIconByAppName = (name : string) => {
    let res = ''
    switch (name) {
      case 'facebookID':
        res = SocialNetworkNames.facebook
        break
      case 'twitterScreenName':
        res = SocialNetworkNames.twitter
        break
      case 'googlePlusID':
        res = SocialNetworkNames.googlePlus
        break
      case 'linkedinPubProfileUrl':
        res = SocialNetworkNames.linkedin
        break
      case 'instagramID' : 
        res = SocialNetworkNames.instagram
        break
    }
    //console.log(name, res)
    return res
  }

  export const returnLinkToSocialNetwork = (name : string, id : string) : string => {
  
      switch (returnIconByAppName(name)) {
        case SocialNetworkNames.facebook:
          return SocialNetworkLinks.facebook + id
        break
        case SocialNetworkNames.linkedin:
          return id
          break
        case SocialNetworkNames.twitter: 
          return SocialNetworkLinks.twitter + id
        break
        case SocialNetworkNames.instagram : 
          return SocialNetworkLinks.instagram + id
        break
        case SocialNetworkNames.googlePlus : 
          return SocialNetworkLinks.googlePlus + id
        break
      }
      return ""
    }

    export const convertToRealName = (jsonName : string) : string => {
      switch (returnIconByAppName(jsonName)) {
        case SocialNetworkNames.facebook:
          return SocialNetwoksRealNames.facebook
        break
        case SocialNetworkNames.linkedin:
          return SocialNetwoksRealNames.linkedin
          break
        case SocialNetworkNames.twitter: 
          return SocialNetwoksRealNames.twitter
        break
        case SocialNetworkNames.instagram : 
          return SocialNetwoksRealNames.instagram
        break
        case SocialNetworkNames.googlePlus : 
          return SocialNetwoksRealNames.googlePlus
        break
      }
      return ""
    }