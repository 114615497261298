import { getMessaging, onMessage, isSupported } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import { store } from './store'
import {
  addNewNotification,
  updateSearcherTableRowPercentage,
} from './store/common/actionCreator'
import { fetchResultById } from './store/searcher/actionCreator'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const handleFirebaseNotificationMessage = ({ notification }: any) => {
  const { title, body: json } = notification
  const {
    notificationId: id,
    id: requestMonitorId,
    type,
    message,
    progress,
  } = JSON.parse(String(json))

  console.log('notification', notification)

  if (progress) {
    store.dispatch(
      updateSearcherTableRowPercentage(
        id || requestMonitorId,
        title.toLowerCase(),
        progress,
      ),
    )
    return
  }

  store.dispatch(
    addNewNotification([{ id, requestMonitorId, type, title, text: message }]),
  )

  if (type === 'search') {
    store.dispatch(fetchResultById(requestMonitorId))
  }
}

export const init = () => {
  initializeApp(firebaseConfig)

  isSupported().then(browserSupported => {
    if (browserSupported) {
      const messaging = getMessaging()

      onMessage(messaging, (payload) => {
        console.log('message!!!!', payload)
        handleFirebaseNotificationMessage(payload)
      })
    }
  })

}
